import { render, staticRenderFns } from "./robotSelectedSetup.vue?vue&type=template&id=15d9b331&scoped=true&"
import script from "./robotSelectedSetup.vue?vue&type=script&lang=js&"
export * from "./robotSelectedSetup.vue?vue&type=script&lang=js&"
import style0 from "./robotSelectedSetup.vue?vue&type=style&index=0&id=15d9b331&lang=less&scoped=true&"
import style1 from "./robotSelectedSetup.vue?vue&type=style&index=1&id=15d9b331&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15d9b331",
  null
  
)

export default component.exports