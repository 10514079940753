<template>
  <div class="whole">
    <!-- 机器人设置 -->
    <van-nav-bar
      :title="returnRobotTit(type)"
      left-arrow
      @click-left="onClickLeft"
      safe-area-inset-top
      fixed
      z-index="9999"
    />
    <div class="g_safe_top">
      <div class="layoutBox bgGray robotSetup">
        <div class="robotBox">
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img
                  src="@/assets/imgs/new_color/setup_icon_dealfor.png"
                  alt=""
                />
              </div>
              {{ $t("system.currency") }}
            </div>
            <div class="right">
              {{ dataInfo.currency_pair }}
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img
                  src="@/assets/imgs/new_color/setup_icon_exchange.png"
                  alt=""
                />
              </div>
              {{ $t("system.exchange") }}
            </div>
            <div class="right" style="text-transform: uppercase">
              <span v-if="dataInfo.exchange == 'bian'">{{
                $t("system.bian")
              }}</span>
              <span v-else-if="dataInfo.exchange == 'okex'">{{
                $t("system.OKEx")
              }}</span>
              <span v-else-if="dataInfo.exchange == 'huobi'">{{
                $t("system.huobi")
              }}</span>
              <span v-else>{{ dataInfo.exchange }}</span>
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img
                  src="@/assets/imgs/new_color/setup_icon_strategy.png"
                  alt=""
                />
              </div>
              {{ $t("system.strategy") }}
            </div>
            <div class="right">
              {{ returnRobot(type) }}
            </div>
          </div>
          <div
            class="list-cell"
            v-if="
              dataInfo.type == 'wangge' ||
              dataInfo.type == 'wangge2' ||
              dataInfo.type == 'dibu_gua' ||
              dataInfo.type == 'wangge_m' ||
              dataInfo.type == 'wangge_f' ||
              dataInfo.type == 'wangge_x'
            "
          >
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_loop.png" alt="" />
              </div>
              {{ $t("system.Cycleway") }}
            </div>
            <div class="right">
              <van-radio-group
                v-model="dataInfo.sub_type"
                direction="horizontal"
              >
                <!-- 循环方式(1：单次循环；10000：连续循环) -->
                <van-radio name="1">
                  <template #icon="props">
                    <img
                      class="img-icon"
                      style="width: 0.32rem; display: block"
                      :src="
                        require('@/assets/imgs/new_color/' +
                          (props.checked ? 'icon_choose' : 'icon_unchoose') +
                          '.png')
                      "
                    />
                  </template>
                  {{ $t("system.Onceloop") }}
                </van-radio>
                <van-radio name="10000">
                  <template #icon="props">
                    <img
                      class="img-icon"
                      style="width: 0.32rem; display: block"
                      :src="
                        require('@/assets/imgs/new_color/' +
                          (props.checked ? 'icon_choose' : 'icon_unchoose') +
                          '.png')
                      "
                    />
                  </template>
                  {{ $t("system.Continuousloop") }}
                </van-radio>
              </van-radio-group>
            </div>
          </div>
          <div class="list-cell" v-if="dataInfo.type == 'wave_f'">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/fangxiang.png" alt="" />
              </div>
              {{ $t("system.zuodanfangxiang") }}
            </div>
            <div class="right">
              <van-radio-group v-model="robotParam.v8" direction="horizontal">
                <!-- 循环方式(1：做多；2：做空) -->
                <van-radio name="1">
                  <template #icon="props">
                    <img
                      class="img-icon"
                      style="width: 0.32rem; display: block"
                      :src="
                        require('@/assets/imgs/new_color/' +
                          (props.checked ? 'icon_choose' : 'icon_unchoose') +
                          '.png')
                      "
                    />
                  </template>
                  {{ $t("system.zuoduo") }}
                </van-radio>
                <van-radio name="2">
                  <template #icon="props">
                    <img
                      class="img-icon"
                      style="width: 0.32rem; display: block"
                      :src="
                        require('@/assets/imgs/new_color/' +
                          (props.checked ? 'icon_choose' : 'icon_unchoose') +
                          '.png')
                      "
                    />
                  </template>
                  {{ $t("system.zuokong") }}
                </van-radio>
              </van-radio-group>
            </div>
          </div>
          <div class="list-cell" v-if="dataInfo.type == 'wave_f_x_double'">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/fangxiang.png" alt="" />
              </div>
              {{ $t("system.zuodanfangxiang") }}
            </div>
            <div class="right2">
              <van-radio-group v-model="robotParam.v4" direction="horizontal">
                <!-- 循环方式(1：做多；2：做空) -->
                <van-radio name="1">
                  <template #icon="props">
                    <img
                      class="img-icon"
                      style="width: 0.32rem; display: block"
                      :src="
                        require('@/assets/imgs/new_color/' +
                          (props.checked ? 'icon_choose' : 'icon_unchoose') +
                          '.png')
                      "
                    />
                  </template>
                  {{ $t("system.zuoduo") }}
                </van-radio>
                <van-radio name="2">
                  <template #icon="props">
                    <img
                      class="img-icon"
                      style="width: 0.32rem; display: block"
                      :src="
                        require('@/assets/imgs/new_color/' +
                          (props.checked ? 'icon_choose' : 'icon_unchoose') +
                          '.png')
                      "
                    />
                  </template>
                  {{ $t("system.zuokong") }}
                </van-radio>
              </van-radio-group>
            </div>
          </div>
          <div class="list-cell" v-if="dataInfo.type == 'wangge_f'">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/fangxiang.png" alt="" />
              </div>
              {{ $t("system.zuodanfangxiang") }}
            </div>
            <div class="right">
              <van-radio-group v-model="robotParam.v10" direction="horizontal">
                <!-- 循环方式(1：做多；2：做空) -->
                <van-radio name="1">
                  <template #icon="props">
                    <img
                      class="img-icon"
                      style="width: 0.32rem; display: block"
                      :src="
                        require('@/assets/imgs/new_color/' +
                          (props.checked ? 'icon_choose' : 'icon_unchoose') +
                          '.png')
                      "
                    />
                  </template>
                  {{ $t("system.zuoduo") }}
                </van-radio>
                <van-radio name="2">
                  <template #icon="props">
                    <img
                      class="img-icon"
                      style="width: 0.32rem; display: block"
                      :src="
                        require('@/assets/imgs/new_color/' +
                          (props.checked ? 'icon_choose' : 'icon_unchoose') +
                          '.png')
                      "
                    />
                  </template>
                  {{ $t("system.zuokong") }}
                </van-radio>
              </van-radio-group>
            </div>
          </div>
          <div class="list-cell" v-if="dataInfo.type == 'wave_f_coin'">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/fangxiang.png" alt="" />
              </div>
              {{ $t("system.zuodanfangxiang") }}
            </div>
            <div class="right">
              <van-radio-group v-model="robotParam.v8" direction="horizontal">
                <!-- 循环方式(1：做多；2：做空) -->
                <van-radio name="1">
                  <template #icon="props">
                    <img
                      class="img-icon"
                      style="width: 0.32rem; display: block"
                      :src="
                        require('@/assets/imgs/new_color/' +
                          (props.checked ? 'icon_choose' : 'icon_unchoose') +
                          '.png')
                      "
                    />
                  </template>
                  {{ $t("system.zuoduo") }}
                </van-radio>
                <van-radio name="2">
                  <template #icon="props">
                    <img
                      class="img-icon"
                      style="width: 0.32rem; display: block"
                      :src="
                        require('@/assets/imgs/new_color/' +
                          (props.checked ? 'icon_choose' : 'icon_unchoose') +
                          '.png')
                      "
                    />
                  </template>
                  {{ $t("system.zuokong") }}
                </van-radio>
              </van-radio-group>
            </div>
          </div>
        </div>

        <!-- 海浪趋势设置 -->
        <div class="robotBox" v-if="dataInfo.type == 'wangge_m'">
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_open.png" alt="" />
              </div>
              {{ $t("system.OpeningAmount") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v1" />
              {{ dataInfo.currency_pair | danwei }}
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/cishu.png" alt="" />
              </div>
              {{ $t("system.Replenishmenttimes") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v2" />
              {{ $t("system.ci") }}
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_stop.png" alt="" />
              </div>
              {{ $t("system.Profitmargin") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v3" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/zhiying_hui.png" alt="" />
              </div>
              {{ $t("system.Profitstopcallback") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v4" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_stop.png" alt="" />
              </div>
              {{ $t("system.Marginofreplenishment") }}
            </div>
            <div class="right1" @click="showPopup">
              <!-- <input class="inp" type="text" v-model="robotParam.v8" /> -->
              <div>{{ $t("system.setup") }}</div>
              <div class="icon1">
                <img src="@/assets/imgs/new_color/set.png" alt="" />
              </div>
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img
                  src="@/assets/imgs/new_color/setup_icon_callback.png"
                  alt=""
                />
              </div>
              {{ $t("system.Makeupcallback") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v12" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/pingtui.png" alt="" />
              </div>
              {{ $t("system.Horizontalpushornot") }}
            </div>
            <div class="right">
              <van-checkbox class="checkbox" v-model="robotParam.v13">
                <template #icon="props">
                  <img
                    class="img-icon"
                    style="width: 0.32rem"
                    :src="
                      require('@/assets/imgs/new_color/' +
                        (props.checked ? 'icon_choose' : 'icon_unchoose') +
                        '.png')
                    "
                  />
                </template>
              </van-checkbox>
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_stop.png" alt="" />
              </div>
              {{ $t("system.pingtuifudu") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v14" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/bu_beishu.png" alt="" />
              </div>
              {{ $t("system.bucangbeishu") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v15" />
            </div>
          </div>
          <van-popup
            v-model="show"
            round
            position="bottom"
            :style="{ height: '70%', backgroundColor: '#F8F8F8' }"
          >
            <div class="tan">
              <div class="list-cell" style="padding-bottom: 0.35rem">
                <div class="left">
                  <div class="time">
                    {{ $t("system.Marginofreplenishment") }}
                  </div>
                </div>
                <div class="right" @click="close">{{ $t("tankuang.no") }}</div>
              </div>
              <div class="list-cell">
                <div class="left">
                  {{ $t("system.shoucibucang") }}
                </div>
                <div class="right">
                  <input class="inp1" type="text" v-model="robotParam.v5" /> %
                </div>
              </div>
              <div class="list-cell">
                <div class="left">
                  {{ $t("system.di2cibucang") }}
                </div>
                <div class="right">
                  <input class="inp1" type="text" v-model="robotParam.v6" /> %
                </div>
              </div>
              <div class="list-cell">
                <div class="left">
                  {{ $t("system.di3cibucang") }}
                </div>
                <div class="right">
                  <input class="inp1" type="text" v-model="robotParam.v7" /> %
                </div>
              </div>
              <div class="list-cell">
                <div class="left">
                  {{ $t("system.di4cibucang") }}
                </div>
                <div class="right">
                  <input class="inp1" type="text" v-model="robotParam.v8" /> %
                </div>
              </div>
              <div class="list-cell">
                <div class="left">
                  {{ $t("system.di5cibucang") }}
                </div>
                <div class="right">
                  <input class="inp1" type="text" v-model="robotParam.v9" /> %
                </div>
              </div>
              <div class="list-cell">
                <div class="left">
                  {{ $t("system.di6cibucang") }}
                </div>
                <div class="right">
                  <input class="inp1" type="text" v-model="robotParam.v10" /> %
                </div>
              </div>
              <div class="list-cell1">
                <div class="left">
                  {{ $t("system.di7cibucang") }}
                </div>
                <div class="right">
                  <input class="inp1" type="text" v-model="robotParam.v11" /> %
                </div>
              </div>
              <div class="quedin" @click="close">{{$t('tankuang.yes')}}</div>
            </div>
          </van-popup>
        </div>

        <!-- 海浪网格设置 -->
        <div class="robotBox" v-if="dataInfo.type == 'wave'">
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_open.png" alt="" />
              </div>
              {{ $t("system.OpeningAmount") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v1" />
              {{ dataInfo.currency_pair | danwei }}
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img
                  src="@/assets/imgs/new_color/setup_icon_callback.png"
                  alt=""
                />
              </div>
              {{ $t("system.Zuidabucangcishu") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v2" />
              {{ $t("system.ci") }}
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_stop.png" alt="" />
              </div>
              {{ $t("system.OneProfitmargin") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v3" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/zhiying_hui.png" alt="" />
              </div>
              {{ $t("system.Wanggekuandu") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v4" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_stop.png" alt="" />
              </div>
              {{ $t("system.Suofanglv") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v5" />
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img
                  src="@/assets/imgs/new_color/setup_icon_callback.png"
                  alt=""
                />
              </div>
              {{ $t("system.Bucangjinersuofanglv") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v6" />
            </div>
          </div>
        </div>

        <!-- 智能海浪合约设置 -->
        <div class="robotBox" v-if="dataInfo.type == 'wave_f_x_double'">
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_open.png" alt="" />
              </div>
              {{ $t("system.OpeningAmount") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v1" />
              {{ dataInfo.currency_pair | danwei }}
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img
                  src="@/assets/imgs/new_color/setup_icon_callback.png"
                  alt=""
                />
              </div>
              {{ $t("system.Zuidabucangcishu") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v2" />
              {{ $t("system.ci") }}
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/ganggan_beishu.png" alt="" />
              </div>
              {{ $t("system.gangganbeishu") }}
            </div>
            <div class="right">
              <input
                class="inp"
                type="text"
                v-model="robotParam.v3"
                @focus="showPeriodChoice1 = true"
              />
              <!-- <span class="xiala" @click="xiala()"><img src="@/assets/imgs/new_color/xiala.png" alt=""></span> -->
            </div>
          </div>
        </div>
        <!-- 解构马丁设置 -->
        <div class="robotBox" v-if="dataInfo.type == 'wangge_x'">
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/icon/setup_icon_open.png" alt="" />
              </div>
              {{ $t("system.OpeningAmount") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v1" />
              {{ dataInfo.currency_pair | danwei }}
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/icon/setup_icon_repair.png" alt="" />
              </div>
              {{ $t("system.Replenishmenttimes") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v2" />
              {{ $t("system.ci") }}
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/icon/setup_icon_stop.png" alt="" />
              </div>
              {{ $t("system.Profitmargin") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v3" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/icon/setup_icon_callback.png" alt="" />
              </div>
              {{ $t("system.Profitstopcallback") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v4" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/icon/setup_icon_range.png" alt="" />
              </div>
              {{ $t("system.Marginofreplenishment") }}
            </div>
            <div class="right2" @click="showPopup">{{$t('system.toSet')}}</div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img
                  src="@/assets/imgs/icon/setup_icon_replenishment.png"
                  alt=""
                />
              </div>
              {{ $t("system.Makeupcallback") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v31" /> %
            </div>
          </div>
          <van-popup
            v-model="show"
            round
            position="bottom"
            :style="{ height: '50%', backgroundColor: '#F8F8F8' }"
          >
            <div class="list_item0">
              <div class="left">
                <div class="time">{{ $t("system.Marginofreplenishment") }}</div>
              </div>
              <div class="right" @click="close">{{ $t("tankuang.no") }}</div>
            </div>
            <div class="tan1">
              <div class="list_item">
                <div class="item_left">
                  <div class="left">
                    {{ $t("system.shoucibucang") }}
                  </div>
                  <div class="right">
                    <input class="inp1" type="text" v-model="robotParam.v5" /> %
                  </div>
                </div>
                <div class="item_right">
                  <div class="left">
                    {{ $t("system.beishu") }}
                  </div>
                  <div class="right">
                    <input class="inp1" type="text" v-model="robotParam.v6" />
                  </div>
                </div>
              </div>
              <div class="list_item">
                <div class="item_left">
                  <div class="left">
                    {{ $t("system.di2cibucang") }}
                  </div>
                  <div class="right">
                    <input class="inp1" type="text" v-model="robotParam.v7" /> %
                  </div>
                </div>
                <div class="item_right">
                  <div class="left">
                    {{ $t("system.beishu") }}
                  </div>
                  <div class="right">
                    <input class="inp1" type="text" v-model="robotParam.v8" />
                  </div>
                </div>
              </div>
              <div class="list_item">
                <div class="item_left">
                  <div class="left">
                    {{ $t("system.di3cibucang") }}
                  </div>
                  <div class="right">
                    <input class="inp1" type="text" v-model="robotParam.v9" /> %
                  </div>
                </div>
                <div class="item_right">
                  <div class="left">
                    {{ $t("system.beishu") }}
                  </div>
                  <div class="right">
                    <input class="inp1" type="text" v-model="robotParam.v10" />
                  </div>
                </div>
              </div>
              <div class="list_item">
                <div class="item_left">
                  <div class="left">
                    {{ $t("system.di4cibucang") }}
                  </div>
                  <div class="right">
                    <input class="inp1" type="text" v-model="robotParam.v11" />
                    %
                  </div>
                </div>
                <div class="item_right">
                  <div class="left">
                    {{ $t("system.beishu") }}
                  </div>
                  <div class="right">
                    <input class="inp1" type="text" v-model="robotParam.v12" />
                  </div>
                </div>
              </div>
              <div class="list_item">
                <div class="item_left">
                  <div class="left">
                    {{ $t("system.di5cibucang") }}
                  </div>
                  <div class="right">
                    <input class="inp1" type="text" v-model="robotParam.v13" />
                    %
                  </div>
                </div>
                <div class="item_right">
                  <div class="left">
                    {{ $t("system.beishu") }}
                  </div>
                  <div class="right">
                    <input class="inp1" type="text" v-model="robotParam.v14" />
                  </div>
                </div>
              </div>
              <div class="list_item">
                <div class="item_left">
                  <div class="left">
                    {{ $t("system.di6cibucang") }}
                  </div>
                  <div class="right">
                    <input class="inp1" type="text" v-model="robotParam.v15" />
                    %
                  </div>
                </div>
                <div class="item_right">
                  <div class="left">
                    {{ $t("system.beishu") }}
                  </div>
                  <div class="right">
                    <input class="inp1" type="text" v-model="robotParam.v16" />
                  </div>
                </div>
              </div>
              <div class="list_item">
                <div class="item_left">
                  <div class="left">
                    {{ $t("system.di7cibucang") }}
                  </div>
                  <div class="right">
                    <input class="inp1" type="text" v-model="robotParam.v17" />
                    %
                  </div>
                </div>
                <div class="item_right">
                  <div class="left">
                    {{ $t("system.beishu") }}
                  </div>
                  <div class="right">
                    <input class="inp1" type="text" v-model="robotParam.v18" />
                  </div>
                </div>
              </div>
              <div class="list_item">
                <div class="item_left">
                  <div class="left">
                    {{ $t("system.di8cibucang") }}
                  </div>
                  <div class="right">
                    <input class="inp1" type="text" v-model="robotParam.v19" />
                    %
                  </div>
                </div>
                <div class="item_right">
                  <div class="left">
                    {{ $t("system.beishu") }}
                  </div>
                  <div class="right">
                    <input class="inp1" type="text" v-model="robotParam.v20" />
                  </div>
                </div>
              </div>
              <div class="list_item">
                <div class="item_left">
                  <div class="left">
                    {{ $t("system.di9cibucang") }}
                  </div>
                  <div class="right">
                    <input class="inp1" type="text" v-model="robotParam.v21" />
                    %
                  </div>
                </div>
                <div class="item_right">
                  <div class="left">
                    {{ $t("system.beishu") }}
                  </div>
                  <div class="right">
                    <input class="inp1" type="text" v-model="robotParam.v22" />
                  </div>
                </div>
              </div>
              <div class="list_item">
                <div class="item_left">
                  <div class="left">
                    {{ $t("system.di10cibucang") }}
                  </div>
                  <div class="right">
                    <input class="inp1" type="text" v-model="robotParam.v23" />
                    %
                  </div>
                </div>
                <div class="item_right">
                  <div class="left">
                    {{ $t("system.beishu") }}
                  </div>
                  <div class="right">
                    <input class="inp1" type="text" v-model="robotParam.v24" />
                  </div>
                </div>
              </div>
              <div class="list_item">
                <div class="item_left">
                  <div class="left">
                    {{ $t("system.di11cibucang") }}
                  </div>
                  <div class="right">
                    <input class="inp1" type="text" v-model="robotParam.v25" />
                    %
                  </div>
                </div>
                <div class="item_right">
                  <div class="left">
                    {{ $t("system.beishu") }}
                  </div>
                  <div class="right">
                    <input class="inp1" type="text" v-model="robotParam.v26" />
                  </div>
                </div>
              </div>
              <div class="list_item">
                <div class="item_left">
                  <div class="left">
                    {{ $t("system.di12cibucang") }}
                  </div>
                  <div class="right">
                    <input class="inp1" type="text" v-model="robotParam.v27" />
                    %
                  </div>
                </div>
                <div class="item_right">
                  <div class="left">
                    {{ $t("system.beishu") }}
                  </div>
                  <div class="right">
                    <input class="inp1" type="text" v-model="robotParam.v28" />
                  </div>
                </div>
              </div>
              <div class="list_item">
                <div class="item_left">
                  <div class="left">
                    {{ $t("system.di13cibucang") }}
                  </div>
                  <div class="right">
                    <input class="inp1" type="text" v-model="robotParam.v29" />
                    %
                  </div>
                </div>
                <div class="item_right">
                  <div class="left">
                    {{ $t("system.beishu") }}
                  </div>
                  <div class="right">
                    <input class="inp1" type="text" v-model="robotParam.v30" />
                  </div>
                </div>
              </div>
              <div class="quedin1" @click="close">{{$t('tankuang.yes')}}</div>
            </div>
          </van-popup>
        </div>
        <!-- 合约-双向对冲设置 -->
        <div class="robotBox" v-if="dataInfo.type == 'double'">
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_open.png" alt="" />
              </div>
              {{ $t("system.kcl") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v1" />
              {{ $t("system.Contract") }}
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img
                  src="@/assets/imgs/new_color/setup_icon_callback.png"
                  alt=""
                />
              </div>
              {{ $t("system.zdcw") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v2" />
              {{ $t("system.Contract") }}
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_stop.png" alt="" />
              </div>
              {{ $t("system.Profitmargin") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v3" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/zhiying_hui.png" alt="" />
              </div>
              {{ $t("system.Marginofreplenishment") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v4" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_stop.png" alt="" />
              </div>
              {{ $t("system.gangganbeishu") }}
            </div>
            <div class="right">
              <input
                class="inp"
                type="text"
                v-model="robotParam.v5"
                @focus="showPeriodChoice3 = true"
              />
              <!-- <span class="xiala" @click="xiala()"><img src="@/assets/imgs/new_color/xiala.png" alt=""></span> -->
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_open.png" alt="" />
              </div>
              {{ $t("system.estimatedMargin") }}
            </div>
            <div class="right">
              <!-- robotParam.v6 -->
              <div class="inp readonly">{{ computedEstimatedMargin() }}</div>
            </div>
          </div>
        </div>
        <!-- 合约-海浪网格设置 -->
        <div class="robotBox" v-if="dataInfo.type == 'wave_f'">
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_open.png" alt="" />
              </div>
              {{ $t("system.OpeningAmount") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v1" />
              {{ dataInfo.currency_pair | danwei }}
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img
                  src="@/assets/imgs/new_color/setup_icon_callback.png"
                  alt=""
                />
              </div>
              {{ $t("system.Zuidabucangcishu") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v2" />
              {{ $t("system.ci") }}
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_stop.png" alt="" />
              </div>
              {{ $t("system.OneProfitmargin") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v3" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/zhiying_hui.png" alt="" />
              </div>
              {{ $t("system.Wanggekuandu") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v4" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_stop.png" alt="" />
              </div>
              {{ $t("system.Suofanglv") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v5" />
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img
                  src="@/assets/imgs/new_color/setup_icon_callback.png"
                  alt=""
                />
              </div>
              {{ $t("system.Bucangjinersuofanglv") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v6" />
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/ganggan_beishu.png" alt="" />
              </div>
              {{ $t("system.gangganbeishu") }}
            </div>
            <div class="right">
              <span class="inp" @click="showPeriodChoice1 = true"
                >x{{ robotParam.v7 }}</span
              >
              <span class="xiala" @click="xiala()"
                ><img src="@/assets/imgs/new_color/xiala.png" alt=""
              /></span>
            </div>
          </div>
        </div>
        <!-- 海浪对冲多 -->
        <div class="robotBox" v-if="dataInfo.type == 'wave_f_long'">
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/icon/setup_icon_open.png" alt="" />
              </div>
              {{ $t("system.OpeningAmount") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v1" />
              {{ dataInfo.currency_pair | danwei }}
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/icon/setup_icon_repair.png" alt="" />
              </div>
              {{ $t("system.Zuidabucangcishu") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v2" />
              {{ $t("system.ci") }}
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/icon/setup_icon_stop.png" alt="" />
              </div>
              {{ $t("system.OneProfitmargin") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v3" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/icon/setup_icon_callback.png" alt="" />
              </div>
              {{ $t("system.Wanggekuandu") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v4" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/icon/setup_icon_range.png" alt="" />
              </div>
              {{ $t("system.Suofanglv") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v5" />
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img
                  src="@/assets/imgs/icon/setup_icon_replenishment.png"
                  alt=""
                />
              </div>
              {{ $t("system.Bucangjinersuofanglv") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v6" />
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/icon/ganggan.png" alt="" />
              </div>
              {{ $t("system.gangganbeishu") }}
            </div>
            <div class="right">
              <span class="inp" @click="showPeriodChoice1 = true"
                >x{{ robotParam.v7 }}</span
              >
              <span class="xiala" @click="xiala()"
                ><img src="@/assets/imgs/new_color/xiala.png" alt=""
              /></span>
            </div>
          </div>
        </div>
        <!-- 增币策略 -->
        <div class="robotBox" v-if="dataInfo.type == 'wave_f_coin'">
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/icon/setup_icon_open.png" alt="" />
              </div>
              {{ $t("system.OpeningAmount") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v1" />
              {{ dataInfo.token }}
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/icon/setup_icon_repair.png" alt="" />
              </div>
              {{ $t("system.Zuidabucangcishu") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v2" />
              {{ $t("system.ci") }}
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/icon/setup_icon_stop.png" alt="" />
              </div>
              {{ $t("system.OneProfitmargin") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v3" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/icon/setup_icon_callback.png" alt="" />
              </div>
              {{ $t("system.Wanggekuandu") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v4" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/icon/setup_icon_range.png" alt="" />
              </div>
              {{ $t("system.Suofanglv") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v5" />
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img
                  src="@/assets/imgs/icon/setup_icon_replenishment.png"
                  alt=""
                />
              </div>
              {{ $t("system.Bucangjinersuofanglv") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v6" />
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/icon/ganggan.png" alt="" />
              </div>
              {{ $t("system.gangganbeishu") }}
            </div>
            <div class="right">
              <span class="inp" @click="showPeriodChoice1 = true"
                >x{{ robotParam.v7 }}</span
              >
              <span class="xiala" @click="xiala()"
                ><img src="@/assets/imgs/new_color/xiala.png" alt=""
              /></span>
            </div>
          </div>
        </div>
        <!-- 海浪对冲空 -->
        <div class="robotBox" v-if="dataInfo.type == 'wave_f_short'">
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/icon/setup_icon_open.png" alt="" />
              </div>
              {{ $t("system.OpeningAmount") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v1" />
              {{ dataInfo.currency_pair | danwei }}
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/icon/setup_icon_repair.png" alt="" />
              </div>
              {{ $t("system.Zuidabucangcishu") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v2" />
              {{ $t("system.ci") }}
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/icon/setup_icon_stop.png" alt="" />
              </div>
              {{ $t("system.OneProfitmargin") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v3" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/icon/setup_icon_callback.png" alt="" />
              </div>
              {{ $t("system.Wanggekuandu") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v4" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/icon/setup_icon_range.png" alt="" />
              </div>
              {{ $t("system.Suofanglv") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v5" />
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img
                  src="@/assets/imgs/icon/setup_icon_replenishment.png"
                  alt=""
                />
              </div>
              {{ $t("system.Bucangjinersuofanglv") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v6" />
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/icon/ganggan.png" alt="" />
              </div>
              {{ $t("system.gangganbeishu") }}
            </div>
            <div class="right">
              <span class="inp" @click="showPeriodChoice1 = true"
                >x{{ robotParam.v7 }}</span
              >
              <span class="xiala" @click="xiala()"
                ><img src="@/assets/imgs/new_color/xiala.png" alt=""
              /></span>
            </div>
          </div>
        </div>
        <!-- 手动策略多 -->
        <div class="robotBox" v-if="dataInfo.type == 'manual_f_long'">
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/icon/ganggan.png" alt="" />
              </div>
              {{ $t("system.gangganbeishu") }}
            </div>
            <div class="right">
              <span class="inp" @click="showPeriodChoice1 = true"
                >x{{ robotParam.v1 }}</span
              >
              <span class="xiala" @click="xiala()"
                ><img src="@/assets/imgs/icon/xiala.png" alt=""
              /></span>
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_open.png" alt="" />
              </div>
              {{ $t("system.zhisunjiage") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v2" />
            </div>
          </div>
        </div>
        <!-- 手动策略空 -->
        <div class="robotBox" v-if="dataInfo.type == 'manual_f_short'">
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/icon/ganggan.png" alt="" />
              </div>
              {{ $t("system.gangganbeishu") }}
            </div>
            <div class="right">
              <span class="inp" @click="showPeriodChoice1 = true"
                >x{{ robotParam.v1 }}</span
              >
              <span class="xiala" @click="xiala()"
                ><img src="@/assets/imgs/icon/xiala.png" alt=""
              /></span>
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_open.png" alt="" />
              </div>
              {{ $t("system.zhisunjiage") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v2" />
            </div>
          </div>
        </div>
        <!-- 智能马丁设置 -->
        <div class="robotBox" v-if="dataInfo.type == 'wangge'">
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_open.png" alt="" />
              </div>
              {{ $t("system.OpeningAmount") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v1" />
              {{ dataInfo.currency_pair | danwei }}
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/cishu.png" alt="" />
              </div>
              {{ $t("system.Replenishmenttimes") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v2" />
              {{ $t("system.ci") }}
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_stop.png" alt="" />
              </div>
              {{ $t("system.Profitmargin") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v3" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/zhiying_hui.png" alt="" />
              </div>
              {{ $t("system.Profitstopcallback") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v4" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_stop.png" alt="" />
              </div>
              {{ $t("system.Marginofreplenishment") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v5" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img
                  src="@/assets/imgs/new_color/setup_icon_callback.png"
                  alt=""
                />
              </div>
              {{ $t("system.Makeupcallback") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v6" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/pingtui.png" alt="" />
              </div>
              {{ $t("system.Horizontalpushornot") }}
            </div>
            <div class="right">
              <van-checkbox class="checkbox" v-model="robotParam.v7">
                <template #icon="props">
                  <img
                    class="img-icon"
                    style="width: 0.32rem"
                    :src="
                      require('@/assets/imgs/new_color/' +
                        (props.checked ? 'icon_choose' : 'icon_unchoose') +
                        '.png')
                    "
                  />
                </template>
              </van-checkbox>
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/bu_beishu.png" alt="" />
              </div>
              {{ $t("system.bucangbeishu") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v8" />
            </div>
          </div>
        </div>

        <!--合约-智能马丁设置 -->
        <div class="robotBox" v-if="dataInfo.type == 'wangge_f'">
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_open.png" alt="" />
              </div>
              {{ $t("system.OpeningAmount") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v1" />
              {{ dataInfo.currency_pair | danwei }}
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/cishu.png" alt="" />
              </div>
              {{ $t("system.Replenishmenttimes") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v2" />
              {{ $t("system.ci") }}
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_stop.png" alt="" />
              </div>
              {{ $t("system.Profitmargin") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v3" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/zhiying_hui.png" alt="" />
              </div>
              {{ $t("system.Profitstopcallback") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v4" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_stop.png" alt="" />
              </div>
              {{ $t("system.Marginofreplenishment") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v5" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img
                  src="@/assets/imgs/new_color/setup_icon_callback.png"
                  alt=""
                />
              </div>
              {{ $t("system.Makeupcallback") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v6" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/pingtui.png" alt="" />
              </div>
              {{ $t("system.Horizontalpushornot") }}
            </div>
            <div class="right">
              <van-checkbox class="checkbox" v-model="robotParam.v7">
                <template #icon="props">
                  <img
                    class="img-icon"
                    style="width: 0.32rem"
                    :src="
                      require('@/assets/imgs/new_color/' +
                        (props.checked ? 'icon_choose' : 'icon_unchoose') +
                        '.png')
                    "
                  />
                </template>
              </van-checkbox>
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/bu_beishu.png" alt="" />
              </div>
              {{ $t("system.bucangbeishu") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v8" />
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/ganggan_beishu.png" alt="" />
              </div>
              {{ $t("system.gangganbeishu") }}
            </div>
            <div class="right">
              <span class="inp" @click="showPeriodChoice1 = true"
                >x{{ robotParam.v9 }}</span
              >
              <span class="xiala" @click="xiala()"
                ><img src="@/assets/imgs/new_color/xiala.png" alt=""
              /></span>
            </div>
          </div>
        </div>

        <!-- 底部追踪设置 -->
        <div class="robotBox" v-if="
          dataInfo.type == 'wangge2'||
          dataInfo.type == 'dibu_gua'
        ">
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_open.png" alt="" />
              </div>
              {{ $t("system.OpeningAmount") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v1" />
              {{ dataInfo.currency_pair | danwei }}
            </div>
          </div>
          <van-cell-group>
            <div class="list-cell">
              <div class="left">
                <div class="icon">
                  <img
                    src="@/assets/imgs/new_color/setup_icon_clock.png"
                    alt=""
                  />
                </div>
                {{ $t("system.cycle") }}
              </div>
              <div class="right">
                <input
                  class="inp"
                  type="text"
                  v-model="robotParam.v2"
                  @focus="showPeriodChoice = true"
                />
                <i class="icon_arrR" style="margin: 0 0.2rem"></i>
                <span>15m,1h,4h,1d</span>
              </div>
            </div>
            <!-- 周期弹出选择 -->
            <van-action-sheet
              v-model="showPeriodChoice"
              :round="false"
              :actions="periodChoiceSet"
              @select="onSelectPeriod"
            ></van-action-sheet>
          </van-cell-group>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img
                  src="@/assets/imgs/new_color/setup_icon_jiang.png"
                  alt=""
                />
              </div>
              {{ $t("system.extentContinuousDecline") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v3" /> %
            </div>
          </div>

          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/cishu.png" alt="" />
              </div>
              {{ $t("system.Replenishmenttimes") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v4" />
              {{ $t("system.ci") }}
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_stop.png" alt="" />
              </div>
              {{ $t("system.Profitmargin") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v5" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/zhiying_hui.png" alt="" />
              </div>
              {{ $t("system.Profitstopcallback") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v6" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_stop.png" alt="" />
              </div>
              {{ $t("system.Marginofreplenishment") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v7" /> %
            </div>
          </div>
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img
                  src="@/assets/imgs/new_color/setup_icon_callback.png"
                  alt=""
                />
              </div>
              {{ $t("system.Makeupcallback") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v8" /> %
            </div>
          </div>
        </div>
        <div
          class="robotBox"
          v-if="
            dataInfo.type == 'manual_f_long' ||
            dataInfo.type == 'manual_f_short'
          "
        >
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_open.png" alt="" />
              </div>
              {{ $t("system.zhiyingjiage") }}
            </div>
            <div class="right">
              <input class="inp" type="text" v-model="robotParam.v3" />
            </div>
          </div>
        </div>
        <!-- 止损价格 v-else -->
        <div class="robotBox" v-else-if="dataInfo.type != 'double'">
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_open.png" alt="" />
              </div>
              {{ $t("system.zhisunjiage") }}
            </div>
            <div class="right">
              <input
                class="inp"
                type="text"
                v-model="robotParam.v16"
                v-if="dataInfo.type == 'wangge_m'"
              />
              <!-- <input class="inp" type="text" v-model="robotParam.v7"  v-if="dataInfo.type == 'wave'"/> -->
              <!-- <input class="inp" type="text" v-model="robotParam.v9"  v-if="dataInfo.type == 'wangge' || dataInfo.type == 'wangge2'||
                dataInfo.type == 'dibu_gua' || dataInfo.type == 'wave_f' || dataInfo.type == 'wave_f_coin'"/> -->
              <input
                class="inp"
                type="text"
                v-model="robotParam.v9"
                v-if="
                  dataInfo.type == 'wangge' ||
                  dataInfo.type == 'wangge2' ||
                  dataInfo.type == 'dibu_gua'||
                  dataInfo.type == 'wave_f_coin'
                "
              />
              <input
                class="inp"
                type="text"
                v-model="robotParam.v11"
                v-if="dataInfo.type == 'wangge_f'"
              />
              <input
                class="inp"
                type="text"
                v-model="robotParam.v32"
                v-if="dataInfo.type == 'wangge_x'"
              />
              <input
                class="inp"
                type="text"
                v-model="robotParam.v8"
                v-if="
                  dataInfo.type == 'wave_f_long' ||
                  dataInfo.type == 'wave_f_short'
                "
              />

              <van-radio-group
                v-model="stop_price"
                direction="horizontal"
                v-if="
                  dataInfo.type == 'wave_x' ||
                  dataInfo.type == 'wave_f_x_double' ||
                  dataInfo.type == 'wave' ||
                  dataInfo.type == 'wave_f'
                "
              >
                <!-- 循环方式(1：单次循环；10000：连续循环) -->
                <van-radio name="1">
                  <template #icon="props">
                    <img
                      class="img-icon"
                      style="width: 0.32rem; display: block"
                      :src="
                        require('@/assets/imgs/new_color/' +
                          (props.checked ? 'icon_choose' : 'icon_unchoose') +
                          '.png')
                      "
                    />
                  </template>
                  {{ $t("system.fixedStopLoss") }}
                </van-radio>
                <van-radio name="2">
                  <template #icon="props">
                    <img
                      class="img-icon"
                      style="width: 0.32rem; display: block"
                      :src="
                        require('@/assets/imgs/new_color/' +
                          (props.checked ? 'icon_choose' : 'icon_unchoose') +
                          '.png')
                      "
                    />
                  </template>
                  {{ $t("system.floatStopLoss") }}
                </van-radio>
              </van-radio-group>
            </div>
          </div>
          <div v-if="stop_price == '1'">
            <div
              class="mid_list-cell"
              v-if="
                dataInfo.type == 'wave_f' ||
                dataInfo.type == 'wave' ||
                dataInfo.type == 'wave_f_long' ||
                dataInfo.type == 'wave_f_short' ||
                dataInfo.type == 'wave_x' ||
                dataInfo.type == 'wave_f_x_double'
              "
            >
              <div class="left">
                {{ $t("system.fixedStopLoss") }}
              </div>
              <div class="right" v-if="dataInfo.type == 'wave'">
                <input class="inp" type="text" v-model="robotParam.v7" />U
              </div>
              <div class="right" v-if="dataInfo.type == 'wave_x'">
                <input class="inp" type="text" v-model="robotParam.v3" />U
              </div>
              <div class="right" v-if="dataInfo.type == 'wave_f'">
                <input class="inp" type="text" v-model="robotParam.v9" />U
              </div>
              <div class="right" v-if="dataInfo.type == 'wave_f_x_double'">
                <input class="inp" type="text" v-model="robotParam.v5" />U
              </div>
              <div
                class="right"
                v-if="
                  dataInfo.type == 'wave_f_long' ||
                  dataInfo.type == 'wave_f_short'
                "
              >
                <input class="inp" type="text" v-model="robotParam.v8" />U
              </div>
            </div>
          </div>
          <div v-if="stop_price == '2'">
            <div
              class="mid_list-cell"
              v-if="
                dataInfo.type == 'wave_f' ||
                dataInfo.type == 'wave' ||
                dataInfo.type == 'wave_f_long' ||
                dataInfo.type == 'wave_f_short' ||
                dataInfo.type == 'wave_x' ||
                dataInfo.type == 'wave_f_x_double'
              "
            >
              <div class="left">
                {{ $t("system.floatStopLoss") }}
              </div>
              <div class="right" v-if="dataInfo.type == 'wave'">
                <input class="inp" type="text" v-model="robotParam.v7" />%
              </div>
              <div class="right" v-if="dataInfo.type == 'wave_x'">
                <input class="inp" type="text" v-model="robotParam.v3" />%
              </div>
              <div class="right" v-if="dataInfo.type == 'wave_f'">
                <input class="inp" type="text" v-model="robotParam.v9" />%
              </div>
              <div class="right" v-if="dataInfo.type == 'wave_f_x_double'">
                <input class="inp" type="text" v-model="robotParam.v5" />%
              </div>
              <div
                class="right"
                v-if="
                  dataInfo.type == 'wave_f_long' ||
                  dataInfo.type == 'wave_f_short'
                "
              >
                <input class="inp" type="text" v-model="robotParam.v8" />%
              </div>
            </div>
          </div>
        </div>

        <!-- 防暴跌 -->
        <!-- v-if="dataInfo.type != 'wave_f_coin' && dataInfo.type != 'double'" -->
        <div
          class="robotBox"
          v-if="
            dataInfo.type == 'wave' ||
            dataInfo.type == 'wave_f' ||
            dataInfo.type == 'wave_f_coin' ||
            dataInfo.type == 'wave_f_x_double'
          "
        >
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/icon/fangbaodie.png" alt="" />
              </div>
              {{ $t("system.AntiRiotFall") }}
            </div>
            <div class="right">
              <!-- <van-switch v-model="robotParam.v10" active-color="#efba0d" inactive-color="#F7F7FA" size="18px"  v-if="dataInfo.type == 'wangge'"/>
							<van-switch v-model="robotParam.v10" active-color="#efba0d" inactive-color="#F7F7FA" size="18px"  v-if="dataInfo.type == 'wangge2'||dataInfo.type == 'dibu_gua'"/> -->
              <van-switch
                v-model="robotParam.v8"
                active-color="#efba0d"
                inactive-color="#F7F7FA"
                size="18px"
                v-if="dataInfo.type == 'wave'"
              />
              <!-- <van-switch v-model="robotParam.v4" active-color="#efba0d" inactive-color="#F7F7FA" size="18px"  v-if="dataInfo.type == 'wave_x'"/> -->
              <!-- <van-switch v-model="robotParam.v17" active-color="#efba0d" inactive-color="#F7F7FA" size="18px"  v-if="dataInfo.type == 'wangge_m'"/> -->
              <van-switch
                v-model="robotParam.v33"
                active-color="#efba0d"
                inactive-color="#F7F7FA"
                size="18px"
                v-if="dataInfo.type == 'wangge_x'"
              />
              <!-- <van-switch v-model="robotParam.v31" active-color="#efba0d" inactive-color="#F7F7FA" size="18px"  v-if="dataInfo.type == 'marting_d'"/> -->
              <van-switch
                v-model="robotParam.v10"
                active-color="#efba0d"
                inactive-color="#F7F7FA"
                size="18px"
                v-if="dataInfo.type == 'wave_f'"
              />
              <van-switch
                v-model="robotParam.v6"
                active-color="#efba0d"
                inactive-color="#F7F7FA"
                size="18px"
                v-if="dataInfo.type == 'wave_f_x_double'"
              />
              <!-- <van-switch v-model="robotParam.v12" active-color="#efba0d" inactive-color="#F7F7FA" size="18px"  v-if="dataInfo.type == 'wangge_f'"/>
							<van-switch v-model="robotParam.v9" active-color="#efba0d" inactive-color="#F7F7FA" size="18px"  v-if="dataInfo.type == 'wave_f_long' || dataInfo.type == 'wave_f_short'"/>
							<van-switch v-model="robotParam.v4" active-color="#efba0d" inactive-color="#F7F7FA" size="18px"  v-if="dataInfo.type == 'manual_f_long' || dataInfo.type == 'manual_f_short'"/> -->
              <van-switch
                v-model="robotParam.v10"
                active-color="#efba0d"
                inactive-color="#F7F7FA"
                size="18px"
                v-if="dataInfo.type == 'wave_f_coin'"
              />
            </div>
          </div>
          <!-- v-if="
					dataInfo.type == 'wangge' && robotParam.v10 || 
					dataInfo.type == 'wangge2' && robotParam.v10 || 
					dataInfo.type == 'dibu_gua' && robotParam.v10 || 
					dataInfo.type == 'wave' && robotParam.v8 || 
					dataInfo.type == 'wave_x' && robotParam.v4 || 
					dataInfo.type == 'wangge_m' && robotParam.v17 || 
					dataInfo.type == 'marting_d' && robotParam.v31 || 
					dataInfo.type == 'wave_f' && robotParam.v10 || 
					dataInfo.type == 'wave_f_x_double' && robotParam.v6 || 
					dataInfo.type == 'wangge_f' && robotParam.v12 || 
					dataInfo.type == 'wave_f_long' && robotParam.v9 || 
					dataInfo.type == 'wave_f_short' && robotParam.v9 || 
					dataInfo.type == 'manual_f_long' && robotParam.v4 || 
					dataInfo.type == 'manual_f_short' && robotParam.v4" -->
          <div
            v-if="
              (dataInfo.type == 'wave' && robotParam.v8) ||
              (dataInfo.type == 'wangge_x' && robotParam.v33) ||
              (dataInfo.type == 'wave_f' && robotParam.v10) ||
              (dataInfo.type == 'wave_f_coin' && robotParam.v10) ||
              (dataInfo.type == 'wave_f_x_double' && robotParam.v6)
            "
          >
            <div class="mid_list-cell">
              <div class="left">
                {{ $t("system.CycleTime") }}
              </div>
              <van-popover
                v-model="showPopover"
                trigger="click"
                :actions="actions"
                @select="onSelect"
                placement="bottom-end"
              >
                <template #reference>
                  <!-- <div class="right" v-if="dataInfo.type == 'wangge'">
									{{robotParam.v11}}
							    	<img src="../../assets/imgs/icon/xiangxia.png" >
							    </div>
								<div class="right" v-if="dataInfo.type == 'wangge2'||dataInfo.type == 'dibu_gua'">
									{{robotParam.v11}}
									<img src="../../assets/imgs/icon/xiangxia.png" >
								</div> -->
                  <div class="right" v-if="dataInfo.type == 'wave'">
                    {{ robotParam.v9 }}
                    <img src="../../assets/imgs/icon/xiangxia.png" />
                  </div>
                  <!-- <div class="right" v-if="dataInfo.type == 'wave_x'">
									{{robotParam.v5}}
									<img src="../../assets/imgs/icon/xiangxia.png" >
								</div>
								<div class="right" v-if="dataInfo.type == 'wangge_m'">
									{{robotParam.v18}}
									<img src="../../assets/imgs/icon/xiangxia.png" >
								</div> -->
                  <div class="right" v-if="dataInfo.type == 'wangge_x'">
                    {{ robotParam.v34 }}
                    <img src="../../assets/imgs/icon/xiangxia.png" />
                  </div>
                  <!-- <div class="right" v-if="dataInfo.type == 'marting_d'">
									{{robotParam.v32}}
									<img src="../../assets/imgs/icon/xiangxia.png" >
								</div> -->
                  <div class="right" v-if="dataInfo.type == 'wave_f'">
                    {{ robotParam.v11 }}
                    <img src="../../assets/imgs/icon/xiangxia.png" />
                  </div>
                  <div class="right" v-if="dataInfo.type == 'wave_f_x_double'">
                    {{ robotParam.v7 }}
                    <img src="../../assets/imgs/icon/xiangxia.png" />
                  </div>
                  <!-- <div class="right" v-if="dataInfo.type == 'wangge_f'">
									{{robotParam.v13}}
									<img src="../../assets/imgs/icon/xiangxia.png" >
								</div>
								<div class="right" v-if="dataInfo.type == 'wave_f_long' || dataInfo.type == 'wave_f_short'">
									{{robotParam.v10}}
									<img src="../../assets/imgs/icon/xiangxia.png" >
								</div>
								<div class="right" v-if="dataInfo.type == 'manual_f_long' || dataInfo.type == 'manual_f_short'">
									{{robotParam.v5}}
									<img src="../../assets/imgs/icon/xiangxia.png" >
								</div> -->
                  <div class="right" v-if="dataInfo.type == 'wave_f_coin'">
                    {{ robotParam.v11 }}
                    <img src="../../assets/imgs/icon/xiangxia.png" />
                  </div>
                </template>
              </van-popover>
            </div>
            <div class="mid_list-cell">
              <div class="left">
                {{ $t("system.DeclineRange") }}
              </div>
              <!-- <div class="right" v-if="dataInfo.type == 'wangge'">
								<input class="inp" type="text" v-model="robotParam.v12" />%
							</div>
							<div class="right" v-if="dataInfo.type == 'wangge2'||dataInfo.type == 'dibu_gua'">
								<input class="inp" type="text" v-model="robotParam.v12" />%
							</div> -->
              <div class="right" v-if="dataInfo.type == 'wave'">
                <input class="inp" type="text" v-model="robotParam.v10" />%
              </div>
              <!-- <div class="right" v-if="dataInfo.type == 'wave_x'">
								<input class="inp" type="text" v-model="robotParam.v6" />%
							</div>
							<div class="right" v-if="dataInfo.type == 'wangge_m'">
								<input class="inp" type="text" v-model="robotParam.v19" />%
							</div> -->
              <div class="right" v-if="dataInfo.type == 'wangge_x'">
                <input class="inp" type="text" v-model="robotParam.v35" />%
              </div>
              <!-- <div class="right" v-if="dataInfo.type == 'marting_d'">
								<input class="inp" type="text" v-model="robotParam.v33" />%
							</div> -->
              <div class="right" v-if="dataInfo.type == 'wave_f'">
                <input class="inp" type="text" v-model="robotParam.v12" />%
              </div>
              <div class="right" v-if="dataInfo.type == 'wave_f_x_double'">
                <input class="inp" type="text" v-model="robotParam.v8" />%
              </div>
              <!-- <div class="right" v-if="dataInfo.type == 'wangge_f'">
								<input class="inp" type="text" v-model="robotParam.v14" />%
							</div>
							<div class="right" v-if="dataInfo.type == 'wave_f_long' || dataInfo.type == 'wave_f_short'">
								<input class="inp" type="text" v-model="robotParam.v11" />%
							</div>
							<div class="right" v-if="dataInfo.type == 'manual_f_long' || dataInfo.type == 'manual_f_short'">
								<input class="inp" type="text" v-model="robotParam.v6" />%
							</div> -->
              <div class="right" v-if="dataInfo.type == 'wave_f_coin'">
                <input class="inp" type="text" v-model="robotParam.v12" />%
              </div>
            </div>
          </div>
        </div>
        <!-- 解套 -->
        <!-- v-if="dataInfo.type != 'wave_f_coin' && dataInfo.type != 'double'" -->
        <div
          class="robotBox"
          v-if="
            dataInfo.type == 'wave' ||
            dataInfo.type == 'wave_f' ||
            dataInfo.type == 'wave_f_coin' ||
            dataInfo.type == 'wave_f_x_double'
          "
        >
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/icon/jietao.png" alt="" />
              </div>
              {{ $t("system.UnravelingMode") }}
            </div>
            <div class="right">
              <!-- <van-switch v-model="robotParam.v13" active-color="#efba0d" inactive-color="#F7F7FA" size="18px" v-if="dataInfo.type == 'wangge'"/>
							<van-switch v-model="robotParam.v13" active-color="#efba0d" inactive-color="#F7F7FA" size="18px" v-if="dataInfo.type == 'wangge2'||dataInfo.type == 'dibu_gua'"/> -->
              <van-switch
                v-model="robotParam.v11"
                active-color="#efba0d"
                inactive-color="#F7F7FA"
                size="18px"
                v-if="dataInfo.type == 'wave'"
              />
              <!-- <van-switch v-model="robotParam.v7" active-color="#efba0d" inactive-color="#F7F7FA" size="18px" v-if="dataInfo.type == 'wave_x'"/> -->
              <!-- <van-switch v-model="robotParam.v20" active-color="#efba0d" inactive-color="#F7F7FA" size="18px" v-if="dataInfo.type == 'wangge_m'"/>
							<van-switch v-model="robotParam.v36" active-color="#efba0d" inactive-color="#F7F7FA" size="18px" v-if="dataInfo.type == 'wangge_x'"/> -->
              <van-switch
                v-model="robotParam.v13"
                active-color="#efba0d"
                inactive-color="#F7F7FA"
                size="18px"
                v-if="dataInfo.type == 'wave_f'"
              />
              <van-switch
                v-model="robotParam.v9"
                active-color="#efba0d"
                inactive-color="#F7F7FA"
                size="18px"
                v-if="dataInfo.type == 'wave_f_x_double'"
              />
              <!-- <van-switch v-model="robotParam.v15" active-color="#efba0d" inactive-color="#F7F7FA" size="18px" v-if="dataInfo.type == 'wangge_f'"/> -->
              <!-- <van-switch v-model="robotParam.v12" active-color="#efba0d" inactive-color="#F7F7FA" size="18px" v-if="dataInfo.type == 'wave_f_long' || dataInfo.type == 'wave_f_short'"/> -->
              <!-- <van-switch v-model="robotParam.v7" active-color="#efba0d" inactive-color="#F7F7FA" size="18px" v-if="dataInfo.type == 'manual_f_long' || dataInfo.type == 'manual_f_short'"/> -->
              <van-switch
                v-model="robotParam.v13"
                active-color="#efba0d"
                inactive-color="#F7F7FA"
                size="18px"
                v-if="dataInfo.type == 'wave_f_coin'"
              />
            </div>
          </div>
        </div>
        
        <!-- 防爆仓 -->
        <div
          class="robotBox"
          v-if="
            dataInfo.type == 'double'
          "
        >
          <div class="list-cell">
            <div class="left">
              <div class="icon">
                <img src="@/assets/imgs/new_color/setup_icon_dealfor.png" alt="" />
              </div>
              {{ $t("system.liquidation") }}
            </div>
            <div class="right">
              <van-switch
                v-model="robotParam.v7"
                active-color="#efba0d"
                inactive-color="#F7F7FA"
                size="18px"
              />
            </div>
          </div>
        </div>

        <div class="robotBtns">
          <div class="btn" @click="justSaveBotParams()">
            {{ $t("system.save") }}
          </div>
          <div class="btn" @click="saveBotParams()">
            {{ $t("system.Saveandstart") }}
          </div>
        </div>
      </div>
      <!-- 周期弹出选择 -->
      <van-action-sheet
        v-model="showPeriodChoice1"
        :round="false"
        :actions="periodChoiceSet1"
        @select="onSelectPeriod1"
      ></van-action-sheet>
      <!-- </van-cell-group> -->
      <van-dialog
        v-model="isShowPoster"
        show-cancel-button
        :message="$t('system.xiugaifangxiang')"
        @confirm="yes"
      ></van-dialog>
    </div>
  </div>
</template>

<script>
import Decimal from "decimal.js";
import { mapGetters } from "vuex";
export default {
  name: "robotSetup",
  data() {
    return {
      last_robotParam: {},
      times: "1",
      fangxiang: null,
      button_index: null,
      show: false,
      isShowPoster: false,
      showPeriodChoice: false, //弹出周期选择
      showPeriodChoice1: false, //弹出周期选择
      // 周期选择数组
      periodChoiceSet: [
        { name: "15m" },
        { name: "1h" },
        { name: "4h" },
        { name: "1d" },
      ],
      // 周期选择数组
      periodChoiceSet1: [
        { name: "x1" },
        { name: "x2" },
        { name: "x5" },
        { name: "x10" },
        { name: "x20" },
      ],
      // 默认设置参数值--智能马丁+底部追踪
      robotParam: {},
      bot_id: this.$route.query.bot_id, //交易的id
      lastpath: this.$route.query.lastpath, //上一级的最后一个链接地址+参数
      // 20210414[start]
      type: this.$route.query.type, //策略，wangge:智能马丁/wangge2:底部追踪
      dataInfo: {},

      showPopover: false,
      showPopover1: false,
      // 通过 actions 属性来定义菜单选项
      actions: [
        { text: "15m" },
        { text: "1h" },
        { text: "4h" },
        { text: "1d" },
      ],
      actions1: [
        { text: "15m" },
        { text: "1h" },
        { text: "4h" },
        { text: "1d" },
      ],
      stop_price: "1",
    };
  },
  computed: {
    ...mapGetters(["robotData"]),
  },
  created() {
    if (this.robotData) {
      this.initData(this.robotData);
    } else {
      this.getBotParams();
    }
  },
  filters: {
    danwei(v) {
      if (v) {
        let arr = v.split("/");
        return arr[1];
      }
    },
  },
  methods: {
    // 双向对冲计算预估保证金
    computedEstimatedMargin() {
      // 计算方法是 用户的第二个参数最大持仓 * ctvalue / 杠杆倍数(比如5) * 当前价格
      var data = Number(
        new Decimal(this.robotParam.v2)
          .mul(new Decimal(this.dataInfo.ctvalue))
          .div(new Decimal(this.robotParam.v5))
          .mul(new Decimal(this.dataInfo.last))
      );
      this.$set(this.robotParam, "v6", data);
      return data;
    },
    onSelect(e) {
      //   if (this.dataInfo.type == 'wangge') {
      // 	this.robotParam.v11 = e.text
      //   } else if  (
      //   this.dataInfo.type == 'wangge2'||
      //   this.dataInfo.type == 'dibu_gua'
      // ){
      // 	this.robotParam.v11 = e.text
      //   }else
      if (this.dataInfo.type == "wave") {
        this.robotParam.v9 = e.text;
        // } else if (this.dataInfo.type == 'wave_x'){
        // 	  this.robotParam.v5 = e.text
        //   }else if (this.dataInfo.type == 'wangge_m'){
        // 	  this.robotParam.v18 = e.text
      } else if (this.dataInfo.type == "wangge_x") {
        this.robotParam.v34 = e.text;
      } else if (this.dataInfo.type == "wave_f") {
        this.robotParam.v11 = e.text;
      } else if (this.dataInfo.type == "wave_f_x_double") {
        this.robotParam.v7 = e.text;
      }
      // else if (this.dataInfo.type == 'wangge_f') {
      // 	  this.robotParam.v13 = e.text
      //   }else if (this.dataInfo.type == 'wave_f_long' || this.dataInfo.type == 'wave_f_short') {
      // 	  this.robotParam.v10 = e.text
      //   }else if (this.dataInfo.type == 'manual_f_long'|| this.dataInfo.type == 'manual_f_short') {
      // 	  this.robotParam.v5 = e.text
      //   }
      else if (this.dataInfo.type == "wave_f_coin") {
        this.robotParam.v11 = e.text;
      }
    },
    onClickLeft() {
      this.$router.back(-1);
    },
    yes() {
      this.isShowPoster = false;
      if (this.checkForm()) {
        if (this.button_index == 0) {
          this.justSaveBotParamsV2();
        } else {
          this.saveBotParamsV2();
        }
      } else {
        this.$toast(this.$t("system.CannotEmpty"));
      }
    },
    xiala() {
      this.showPeriodChoice1 = true;
    },
    showPopup() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
    // 传递的设置标题参数 type(wangge,wangge2)
    returnRobotTit(type) {
      if (type == "wangge") {
        // return '马丁批量设置'
        // return this.$t('system.wangge') + this.$t('system.Batchsettings')
        return this.$t("system.setupMarting");
      } else if (type == "wangge2") {
        // return '底部追踪批量设置'
        // return this.$t('system.wangge2') + this.$t('system.Batchsettings')
        return this.$t("system.wangge2");
      } else if (type == "dibu_gua") {
        // return '针尖交易批量设置'
        // return this.$t('system.dibu_gua') + this.$t('system.Batchsettings')
        return this.$t("system.dibu_gua");
      } else if (type == "wave") {
        return this.$t("system.wangge3");
      } else if (type == "wangge_m") {
        return this.$t("system.wangge_m");
      } else if (type == "wave_f") {
        return this.$t("system.wave_f");
      } else if (type == "wangge_f") {
        return this.$t("system.wangge_f");
      } else if (type == "wave_f_long") {
        return this.$t("system.wave_f_long");
      } else if (type == "wave_f_short") {
        return this.$t("system.wave_f_short");
			}else if (type == 'wangge_x') {
				return this.$t('system.wangge_x')
      } else if (type == "manual_f_long") {
        return this.$t("system.manual_f_long");
      } else if (type == "manual_f_short") {
        return this.$t("system.manual_f_short");
      } else if (type == "wave_f_coin") {
        return this.$t("system.wave_f_coin");
      } else if (type == "wave_f_x_double") {
        return this.$t("system.wave_f_x_double");
      } else if (type == "double") {
        return this.$t("system.double");
      }
    },
    returnRobot(type) {
      if (type == "wangge") {
        // return '马丁设置'
        return this.$t("system.setupMarting");
      } else if (type == "wangge2") {
        // return '底部追踪'
        return this.$t("system.wangge2");
      } else if (type == "dibu_gua") {
        // return '针尖交易'
        return this.$t("system.dibu_gua");
      } else if (type == "wave") {
        return this.$t("system.wangge3");
      } else if (type == "wangge_m") {
        return this.$t("system.wangge_m");
      } else if (type == "wave_f") {
        return this.$t("system.wave_f");
      } else if (type == "wangge_f") {
        return this.$t("system.wangge_f");
      } else if (type == "wave_f_long") {
        return this.$t("system.wave_f_long");
      } else if (type == "wave_f_short") {
        return this.$t("system.wave_f_short");
			}else if (type == 'wangge_x') {
				return this.$t('system.wangge_x')
      } else if (type == "manual_f_long") {
        return this.$t("system.manual_f_long");
      } else if (type == "manual_f_short") {
        return this.$t("system.manual_f_short");
      } else if (type == "wave_f_coin") {
        return this.$t("system.wave_f_coin");
      } else if (type == "wave_f_x_double") {
        return this.$t("system.wave_f_x_double");
      } else if (type == "double") {
        return this.$t("system.double");
      }
    },
    // 判断参数输入框是否为空
    checkForm() {
      this.last_robotParam = JSON.parse(JSON.stringify(this.robotParam));
      if (this.last_robotParam.v1 === "") {
        return false;
      }
      if (this.last_robotParam.v2 === "") {
        return false;
      }
      if (this.last_robotParam.v3 === "") {
        return false;
      }
      if (this.last_robotParam.v4 === "") {
        return false;
      }
      if (this.last_robotParam.v5 === "") {
        return false;
      }
      if (this.last_robotParam.v6 === "") {
        return false;
      }

      if (this.dataInfo.type == "double") {
        if (this.last_robotParam.v7 == true || !this.robotParam.v7) {
          this.last_robotParam.v7 = 1;
        } else {
          this.last_robotParam.v7 = 0;
        }
      }
      if (
        this.dataInfo.type == "wangge2"||
        this.dataInfo.type == "dibu_gua"
      ) {
        if (this.last_robotParam.v7 === "") {
          return false;
        }
        if (this.last_robotParam.v8 === "") {
          return false;
        }
      }
      if (this.dataInfo.type == "wangge") {
        if (this.last_robotParam.v7 == true) {
          this.last_robotParam.v7 = 1;
        } else {
          this.last_robotParam.v7 = 0;
        }
      } else if (this.dataInfo.type == "wangge_m") {
        if (this.last_robotParam.v13 == true) {
          this.last_robotParam.v13 = 1;
        } else {
          this.last_robotParam.v13 = 0;
        }
      }
      if (this.dataInfo.type == "wave") {
        if (this.last_robotParam.v8 == true) {
          this.last_robotParam.v8 = 1;
        } else {
          this.last_robotParam.v8 = 0;
        }
        if (this.last_robotParam.v11 == true) {
          this.last_robotParam.v11 = 1;
        } else {
          this.last_robotParam.v11 = 0;
        }
      }
      if (this.dataInfo.type == "wave_f_x_double") {
        if (this.last_robotParam.v6 == true) {
          this.last_robotParam.v6 = 1;
        } else {
          this.last_robotParam.v6 = 0;
        }
        if (this.last_robotParam.v9 == true) {
          this.last_robotParam.v9 = 1;
        } else {
          this.last_robotParam.v9 = 0;
        }
      }

			if (this.dataInfo.type == 'wangge_x') {
				if(this.last_robotParam.v33 == true) {
					this.last_robotParam.v33 = 1;
				}else {
					this.last_robotParam.v33 = 0;
				}
				if(this.last_robotParam.v36 == true) {
					this.last_robotParam.v36 = 1;
				}else {
					this.last_robotParam.v36 = 0;
				}
			}

      if (this.dataInfo.type == "wave_f") {
        this.dataInfo.sub_type = 1;
        if (this.last_robotParam.v9 === "") {
          return false;
        }
        if (this.last_robotParam.v10 == true) {
          this.last_robotParam.v10 = 1;
        } else {
          this.last_robotParam.v10 = 0;
        }
        if (this.last_robotParam.v13 == true) {
          this.last_robotParam.v13 = 1;
        } else {
          this.last_robotParam.v13 = 0;
        }
      }
      if (this.dataInfo.type == "wave_f_long") {
        this.dataInfo.sub_type = 1;
        if (this.last_robotParam.v8 === "") {
          return false;
        }
      }
      if (this.dataInfo.type == "wave_f_short") {
        this.dataInfo.sub_type = 1;
        if (this.last_robotParam.v8 === "") {
          return false;
        }
      }
      if (this.dataInfo.type == "wangge_f") {
        if (this.last_robotParam.v7 == true) {
          this.last_robotParam.v7 = 1;
        } else {
          this.last_robotParam.v7 = 0;
        }
        if (this.last_robotParam.v11 === "") {
          return false;
        }
      }
      if (this.dataInfo.type == "manual_f_long") {
        if (this.last_robotParam.v2 == "") {
          this.last_robotParam.v2 = 0;
        }
        if (this.last_robotParam.v3 == "") {
          this.last_robotParam.v3 = 0;
        }
      }
      if (this.dataInfo.type == "manual_f_short") {
        if (this.last_robotParam.v2 == "") {
          this.last_robotParam.v2 = 0;
        }
        if (this.last_robotParam.v3 == "") {
          this.last_robotParam.v3 = 0;
        }
      }
      if (this.dataInfo.type == "wave_f_coin") {
        this.dataInfo.sub_type = 1;
        if (this.last_robotParam.v9 === "") {
          return false;
        }

        if (this.last_robotParam.v10 == true) {
          this.last_robotParam.v10 = 1;
        } else {
          this.last_robotParam.v10 = 0;
        }
        if (this.last_robotParam.v13 == true) {
          this.last_robotParam.v13 = 1;
        } else {
          this.last_robotParam.v13 = 0;
        }
      }

      if (this.dataInfo.type == "wave") {
        if (this.stop_price == "2") {
          this.last_robotParam.v7 = this.last_robotParam.v7 + "%";
        }
      }
      // if(this.dataInfo.type == 'wave_x') {
      // 	if(this.stop_price == "2") {
      // 		this.last_robotParam.v3 = this.last_robotParam.v3 + '%'
      // 	}
      // }
      if (this.dataInfo.type == "wave_f") {
        if (this.stop_price == "2") {
          this.last_robotParam.v9 = this.last_robotParam.v9 + "%";
        }
      }
      if (this.dataInfo.type == "wave_f_x_double") {
        if (this.stop_price == "2") {
          this.last_robotParam.v5 = this.last_robotParam.v5 + "%";
        }
      }
      // if(this.dataInfo.type == 'wave_f_long' || this.dataInfo.type == 'wave_f_short') {
      // 	if(this.stop_price == "2") {
      // 		this.last_robotParam.v8 = this.last_robotParam.v8 + '%'
      // 	}
      // }
      return true;
    },

    // 初始化-获取策略设置信息
    getBotParams() {
      this.$post2("Robot/api/index/getBotParamsV2", {
        bot_id: this.bot_id,
      })
        .then((res) => {
          // console.log(res, res.param_arr)
          this.$store.commit("setRobotData", res);

          this.initData(res);
        })
        .catch((e) => {
          this.$toast.fail(e);
        });
    },
    // 初始化数据 更新数据
    initData(res) {
      this.dataInfo = res;
      // this.robotParam = res.param_arr

      let a = res.param_arr;
      let b = {};
      Object.keys(a)
        .sort()
        .map((item) => (b[item] = a[item]));
      this.robotParam = b;
      if (res.type == "wangge" || res.type == "wangge_f") {
        if (!this.robotParam.v8) {
          this.robotParam.v8 = 2;
        }
        if (this.robotParam.v7 == 0) {
          this.robotParam.v7 = false;
        } else {
          this.robotParam.v7 = true;
        }
      }
      if (res.type == "double") {
        if (!this.robotParam.v6) {
          this.robotParam.v6 = 0;
        }
        if (this.robotParam.v7 == 0) {
          this.robotParam.v7 = false;
        } else {
          this.$set(this.robotParam,'v7',true)
          // this.robotParam.v7 = true;
        }
      }
      if (res.type == "wave_f") {
        this.fangxiang = this.robotParam.v8;
        if (!this.robotParam.v9) {
          this.robotParam.v9 = 0;
        }
        if (this.robotParam.v10 == 0 || !this.robotParam.v10) {
          // this.robotParam.v10 = false;
          this.$set(this.robotParam, "v10", false);
        } else {
          // this.robotParam.v10 = true;
          this.$set(this.robotParam, "v10", false);
        }
        if (!this.robotParam.v11) {
          this.robotParam.v11 = "1h";
        }
        if (!this.robotParam.v12 && this.robotParam.v12 !== 0) {
          this.robotParam.v12 = 20;
        }
        if (this.robotParam.v13 == 0 || !this.robotParam.v13) {
          // this.robotParam.v13 = false;
          this.$set(this.robotParam, "v13", false);
        } else {
          // this.robotParam.v13 = true;
          this.$set(this.robotParam, "v13", false);
        }
      }
      if (res.type == "wave_f_x_double") {
        this.fangxiang = this.robotParam.v4;
        if (!this.robotParam.v5) {
          this.robotParam.v5 = 0;
        }
        if (this.robotParam.v6 == 0) {
          this.robotParam.v6 = false;
        } else {
          this.robotParam.v6 = true;
        }
        if (this.robotParam.v9 == 0) {
          this.robotParam.v9 = false;
        } else {
          this.robotParam.v9 = true;
        }
      }
      if (res.type == "wave_f_long") {
        this.fangxiang = this.robotParam.v8;
        if (!this.robotParam.v8) {
          this.robotParam.v8 = 0;
        }
      }
      if (res.type == "wave_f_short") {
        this.fangxiang = this.robotParam.v8;
        if (!this.robotParam.v8) {
          this.robotParam.v8 = 0;
        }
      }
      if (res.type == "wangge_f") {
        this.fangxiang = this.robotParam.v10;
        if (!this.robotParam.v11) {
          this.robotParam.v11 = 0;
        }
      }
      if (res.type == "wangge_m") {
        if (!this.robotParam.v15) {
          this.robotParam.v15 = 2;
        }
        if (this.robotParam.v13 == 0) {
          this.robotParam.v13 = false;
        } else {
          this.robotParam.v13 = true;
        }
      }
      if (res.type == "wangge") {
        if (!this.robotParam.v9) {
          this.robotParam.v9 = 0;
        }
      }
      if (
        res.type == "wangge2"||
        res.type == "dibu_gua"
      ) {
        if (!this.robotParam.v9) {
          this.robotParam.v9 = 0;
        }
      }
      if (res.type == "wave") {
        if (!this.robotParam.v7) {
          this.robotParam.v7 = 0;
        }
        if (this.robotParam.v8 == 0 || !this.robotParam.v8) {
          // this.robotParam.v8 = false;
          this.$set(this.robotParam, "v8", false);
        } else {
          // this.robotParam.v8 = true;
          this.$set(this.robotParam, "v8", true);
        }
        if (!this.robotParam.v9) {
          this.robotParam.v9 = "1h";
        }
        if (!this.robotParam.v10 && this.robotParam.v10 !== 0) {
          this.robotParam.v10 = 20;
        }
        if (this.robotParam.v11 == 0 || !this.robotParam.v11) {
          // this.robotParam.v11 = false;
          this.$set(this.robotParam, "v11", false);
        } else {
          // this.robotParam.v11 = true;
          this.$set(this.robotParam, "v11", true);
        }
      }
      if (res.type == "wangge_m") {
        if (!this.robotParam.v16) {
          this.robotParam.v16 = 0;
        }
      }
      if (res.type == "manual_f_long") {
        if (!this.robotParam.v2) {
          this.robotParam.v2 = 0;
        }
        if (!this.robotParam.v3) {
          this.robotParam.v3 = 0;
        }
      }
      if (res.type == "manual_f_short") {
        if (!this.robotParam.v2) {
          this.robotParam.v2 = 0;
        }
        if (!this.robotParam.v3) {
          this.robotParam.v3 = 0;
        }
      }
      if (res.type == "wave_f_coin") {
        this.fangxiang = this.robotParam.v8;
        if (!this.robotParam.v9) {
          this.robotParam.v9 = 0;
        }

        if (this.robotParam.v10 == 0 || !this.robotParam.v10) {
          // this.robotParam.v10 = false;
          this.$set(this.robotParam, "v10", false);
        } else {
          // this.robotParam.v10 = true;
          this.$set(this.robotParam, "v10", true);
        }
        if (!this.robotParam.v11) {
          this.robotParam.v11 = "1h";
        }
        if (!this.robotParam.v12 && this.robotParam.v12 !== 0) {
          this.robotParam.v12 = 20;
        }
        if (this.robotParam.v13 == 0 || !this.robotParam.v13) {
          // this.robotParam.v13 = false;
          this.$set(this.robotParam, "v13", false);
        } else {
          // this.robotParam.v13 = true;
          this.$set(this.robotParam, "v13", true);
        }
      }
      if (res.type == "wave") {
        if (this.robotParam.v7.indexOf("%") == -1) {
          this.stop_price = "1";
        } else {
          this.robotParam.v7 = this.robotParam.v7.split("%")[0];
          this.stop_price = "2";
        }
      }
      if (res.type == "wave_f") {
        if (this.robotParam.v9.indexOf("%") == -1) {
          this.stop_price = "1";
        } else {
          this.robotParam.v9 = this.robotParam.v9.split("%")[0];
          this.stop_price = "2";
        }
      }
      if (res.type == "wave_f_x_double") {
        if (this.robotParam.v5.indexOf("%") == -1) {
          this.stop_price = "1";
        } else {
          this.robotParam.v5 = this.robotParam.v5.split("%")[0];
          this.stop_price = "2";
        }
      }
      if(res.type == 'wangge_x'){
        if(this.robotParam.v33 == 0) {
          this.robotParam.v33 = false;
        }else{
          this.robotParam.v33 = true;
        }
        if(this.robotParam.v36 == 0) {
          this.robotParam.v36 = false;
        }else{
          this.robotParam.v36 = true;
        }
      }
      // 没有设置默认循环方式
      if (!this.dataInfo.sub_type) {
        this.dataInfo.sub_type = this.times;
      } else {
        this.dataInfo.sub_type = this.dataInfo.sub_type.toString();
      }
    },

    // 选择周期数据后
    onSelectPeriod(item) {
      this.showPeriodChoice = false;
      this.periodChoice = item.name;
      this.robotParam.v2 = item.name;
    },

    // 选择周期数据后
    onSelectPeriod1(item) {
      this.showPeriodChoice1 = false;
      this.periodChoice = item.name;
      if (this.dataInfo.type == "wave_f") {
        this.robotParam.v7 = item.name.slice(1);
      } else if (this.dataInfo.type == "wave_f_x_double") {
        this.robotParam.v3 = item.name.slice(1);
      } else if (this.dataInfo.type == "wangge_f") {
        this.robotParam.v9 = item.name.slice(1);
      } else if (this.dataInfo.type == "wave_f_long") {
        this.robotParam.v7 = item.name.slice(1);
      } else if (this.dataInfo.type == "wave_f_short") {
        this.robotParam.v7 = item.name.slice(1);
      } else if (this.dataInfo.type == "manual_f_long") {
        this.robotParam.v1 = item.name.slice(1);
      } else if (this.dataInfo.type == "manual_f_short") {
        this.robotParam.v1 = item.name.slice(1);
      } else if (this.dataInfo.type == "wave_f_coin") {
        this.robotParam.v7 = item.name.slice(1);
      }
    },

    // 只保存参数
    justSaveBotParams() {
      this.button_index = 0;
      if (this.dataInfo.type == "wave_f") {
        if (this.robotParam.v8 != this.fangxiang) {
          this.isShowPoster = true;
        } else {
          if (this.checkForm()) {
            this.justSaveBotParamsV2();
          } else {
            this.$toast(this.$t("system.CannotEmpty"));
          }
        }
      } else if (this.dataInfo.type == "wave_f_x_double") {
        if (this.robotParam.v4 != this.fangxiang) {
          this.isShowPoster = true;
        } else {
          if (this.checkForm()) {
            this.justSaveBotParamsV2();
          } else {
            this.$toast(this.$t("system.CannotEmpty"));
          }
        }
      } else if (this.dataInfo.type == "wangge_f") {
        if (this.robotParam.v10 != this.fangxiang) {
          this.isShowPoster = true;
        } else {
          if (this.checkForm()) {
            this.justSaveBotParamsV2();
          } else {
            this.$toast(this.$t("system.CannotEmpty"));
          }
        }
      } else if (this.dataInfo.type == "wave_f_coin") {
        if (this.robotParam.v8 != this.fangxiang) {
          this.isShowPoster = true;
        } else {
          if (this.checkForm()) {
            this.justSaveBotParamsV2();
          } else {
            this.$toast(this.$t("system.CannotEmpty"));
          }
        }
      } else {
        if (this.checkForm()) {
          this.justSaveBotParamsV2();
        } else {
          this.$toast(this.$t("system.CannotEmpty"));
        }
      }
    },
    // 保存并启动
    saveBotParams() {
      this.button_index = 1;
      if (this.dataInfo.type == "wave_f") {
        if (this.robotParam.v8 != this.fangxiang) {
          this.isShowPoster = true;
        } else {
          if (this.checkForm()) {
            this.saveBotParamsV2();
          } else {
            this.$toast(this.$t("system.CannotEmpty"));
          }
        }
      } else if (this.dataInfo.type == "wave_f_x_double") {
        if (this.robotParam.v4 != this.fangxiang) {
          this.isShowPoster = true;
        } else {
          if (this.checkForm()) {
            this.saveBotParamsV2();
          } else {
            this.$toast(this.$t("system.CannotEmpty"));
          }
        }
      } else if (this.dataInfo.type == "wangge_f") {
        if (this.robotParam.v10 != this.fangxiang) {
          this.isShowPoster = true;
        } else {
          if (this.checkForm()) {
            this.saveBotParamsV2();
          } else {
            this.$toast(this.$t("system.CannotEmpty"));
          }
        }
      } else if (this.dataInfo.type == "wave_f_coin") {
        if (this.robotParam.v8 != this.fangxiang) {
          this.isShowPoster = true;
        } else {
          if (this.checkForm()) {
            this.saveBotParamsV2();
          } else {
            this.$toast(this.$t("system.CannotEmpty"));
          }
        }
      } else {
        if (this.checkForm()) {
          this.saveBotParamsV2();
        } else {
          this.$toast(this.$t("system.CannotEmpty"));
        }
      }
    },

    // 更新列表及选中项机器人参数并触发回调
    saveAfterUpdateAndCallback(callback) {
      this.$store.commit("setRobotData", null);
      this.getBotParams();

      // 更新首页列表
      this.$store.dispatch("setExchangeRobotList");
      // 更新量化列表
      this.$store.dispatch("setRobotList");
      //   .then((res) => {
      //     // 更新选中项机器人参数
      //     try {
      //       res.list.forEach((item) => {
      //         if (item.bot_id == this.bot_id) {
      //           this.$store.commit("setRobotData", {
      //             ...item,
      //             currency_pair: item.token,
      //             token: item.token.split("/")[0],
      //           });
      //           throw Error();
      //         }
      //       });
      //     } catch (error) {}

          callback();
      //   });
    },
    //方法------只保存不启动
    justSaveBotParamsV2() {
      let toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t("system.Loading"),
      });

      this.$post2("Robot/api/index/justSaveBotParamsV2", {
        bot_id: this.bot_id,
        state: this.dataInfo.state,
        type: this.dataInfo.type,
        // 可编辑参数
        times: this.dataInfo.sub_type, //循环次数
        botParam: this.last_robotParam, //输入框数据
        noToast: true,
      })
        .then((res) => {
          if (this.dataInfo.type == "wave_f") {
            this.times = 1;
            // this.robotParam.v7 = 'x' + this.robotParam.v7
            this.fangxiang = this.robotParam.v8;
          }
          if (this.dataInfo.type == "wangge_f") {
            if (this.robotParam.v7 == 1) {
              this.robotParam.v7 = true;
            } else {
              this.robotParam.v7 = false;
            }
            // this.robotParam.v9 =  'x' + this.robotParam.v9
            this.fangxiang = this.robotParam.v10;
          }
          // console.log(res);
          // this.$toast.success('保存参数成功！');
          this.$toast.success(this.$t("system.SaveParametersSucceeded"));

          // 更新列表及选中项机器人参数并触发回调
          this.saveAfterUpdateAndCallback(() => {
            this.$router.replace({
              path: "/circularStrategy",
              query: {
                bot_id: this.bot_id,
                homepath: "/quantification",
                robot: this.dataInfo.robot_strategy,
                fangshi: this.$route.query.fangshi,
                exchange: this.$route.query.exchange,
              },
            });
            toast.clear();
          });
        })
        .catch((e) => {
          toast.clear();
          if (this.dataInfo.type == "wave_f") {
            this.times = 1;
            // this.robotParam.v7 = 'x' + this.robotParam.v7
            this.fangxiang = this.robotParam.v8;
          }
          if (this.dataInfo.type == "wangge_f") {
            if (this.robotParam.v7 == 1) {
              this.robotParam.v7 = true;
            } else {
              this.robotParam.v7 = false;
            }
            // this.robotParam.v9 =  'x' + this.robotParam.v9
            this.fangxiang = this.robotParam.v10;
          }
          // console.log(e, "出错");
          // this.$toast.fail('保存参数失败！');
          this.$toast.fail(e);
        });
    },
    //方法------保存并启动
    saveBotParamsV2() {
      this.$post2("Robot/api/index/saveBotParamsV2", {
        bot_id: this.bot_id,
        type: this.dataInfo.type,
        // 可编辑参数
        times: this.dataInfo.sub_type, //循环次数
        botParam: this.last_robotParam,
        noToast: true,
      })
        .then((res) => {
          if (this.dataInfo.type == "wave_f") {
            this.times = 1;
            // this.robotParam.v7 = 'x' + this.robotParam.v7
            this.fangxiang = this.robotParam.v8;
          }
          if (this.dataInfo.type == "wangge_f") {
            if (this.robotParam.v7 == 1) {
              this.robotParam.v7 = true;
            } else {
              this.robotParam.v7 = false;
            }
            // this.robotParam.v9 =  'x' + this.robotParam.v9
            this.fangxiang = this.robotParam.v10;
          }
          // console.log(res);
          // this.$toast.success('保存并启动成功！');
          this.$toast.success(this.$t("system.SaveStartSuccessfully"));

          // 更新列表及选中项机器人参数并触发回调
          this.saveAfterUpdateAndCallback(() => {
            this.$router.replace({
              path: "/circularStrategy",
              query: {
                bot_id: this.bot_id,
                homepath: "/quantification",
                robot: this.dataInfo.robot_strategy,
                fangshi: this.$route.query.fangshi,
                exchange: this.$route.query.exchange,
              },
            });
          });
        })
        .catch((e) => {
          if (this.dataInfo.type == "wave_f") {
            this.times = 1;
            // this.robotParam.v7 = 'x' + this.robotParam.v7
            this.fangxiang = this.robotParam.v8;
          }
          if (this.dataInfo.type == "wangge_f") {
            if (this.robotParam.v7 == 1) {
              this.robotParam.v7 = true;
            } else {
              this.robotParam.v7 = false;
            }
            // this.robotParam.v9 =  'x' + this.robotParam.v9
            this.fangxiang = this.robotParam.v10;
          }
          if (e == "用户燃料费不足") {
            this.$toast.fail(this.$t("system.gasInsufficient"));
            return;
          }
          // console.log(e, "出错");
          // this.$toast.fail('保存参数失败！');
          // this.$toast.fail(this.$t('system.' + e));
          this.$toast.fail(e);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.van-field__control {
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-width: 0;
  margin: 0;
  padding: 0;
  color: #373737;
  // text-align: left;
  text-align: right;
  background-color: transparent;
  border: 0;
  resize: none;
}

.middedStyle {
  color: #a5a5a5;
  background: #f1f1f1;
  font-size: 0.2rem;
  height: 0.8rem;
  padding: 0.15rem;
}

.btnFdivStyle {
  text-align: center;
}

.btnStyle {
  margin-top: 1rem;
  width: 4.2rem;
}

.c_field {
  font-size: 14px;
  padding: 0.2rem 16px;
}

.inputStyle {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  color: #8b7575;
  padding: 0.1rem;
}

.btnOp {
  margin-top: 0rem;
  margin-bottom: 0.1rem;
  text-align: center;
}

.fontStyle {
  font-size: 0.1rem;
  color: #9b9b9b;
  margin: 0.3rem;
}

.iconStyle {
  margin-right: 0.15rem;
}

.ulList > li {
  padding: 0.3rem;
  margin: 0.3rem;
  background: #ffffff;
  border-radius: 5px;
}

.listStyle {
  background: #ffffff;
  border-radius: 5px;
}

.roleBtnStyle {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  border-radius: 5px;
}

.tpuschool {
  background: #f1f1f3;
  height: 100%;
  width: 100%;
  margin-bottom: 2rem;
}

// .whole {
// background: #ffffff;
// }
.title {
  background: #f6f8f9;
  height: 2rem;
  padding: 0.3rem;
}

.bottomBtnStyle {
  width: 100%;
  background: #e1c364;
  border-radius: 5px;
  border: 0px;
}

.titleGuanLiStyle {
  font-size: 0.4rem;
  padding-top: 1.5rem;
}

.titlePStyle {
  margin-bottom: 0.2rem;
  margin-top: 0.3rem;
  padding-top: 0.1rem;
  transform: rotate(180deg);
}

// ----------20200410[start]---------
.layoutBox {
  padding: 0.2rem;
  min-height: 93vh;
  font-family: PingFangSC-Regular;
}

.robotBox {
  background: white;
  border-radius: 0.12rem;
  margin-bottom: 0.2rem;
  overflow: hidden;

  .van-radio--horizontal {
    margin: 0 0 0 0.4rem;
  }
}

.list-cell {
  font-size: 0.28rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 0.2rem;

  // border-bottom: 1px solid #EDEDED;
  .left {
    color: #6f7a8b;
    display: flex;
    align-items: center;
  }

  .icon {
    margin-right: 0.2rem;

    img {
      width: 0.44rem;
      display: block;
    }
  }

  .icon1 {
    margin-left: 0.1rem;

    img {
      width: 0.355rem;
      display: block;
    }
  }

  .right {
    color: #333333;
  }

  .right1 {
    display: flex;
    align-items: center;
    color: #333333;
  }

  .readonly {
    color: #aaa;
  }

  .inp {
    height: 0.48rem;
    border: 0;
    width: 2rem;
    text-align: right;
  }

  .inp1 {
    height: 0.48rem;
    border: 0;
    width: 2rem;
    text-align: right;
    background-color: #f8f8f8;
  }
}

.list-cell1 {
  font-size: 0.28rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 0.25rem;

  .left {
    color: #666666;
    display: flex;
    align-items: center;
  }

  .icon {
    margin-right: 0.1rem;

    img {
      width: 0.48rem;
      display: block;
    }
  }

  .right {
    color: #373737;
  }

  .inp {
    height: 0.48rem;
    border: 0;
    width: 2rem;
    text-align: right;
  }

  .inp1 {
    height: 0.48rem;
    border: 0;
    width: 2rem;
    text-align: right;
    background-color: #f8f8f8;
  }
}

.robotBtns {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;

  .btn {
    font-size: 0.32rem;
    color: #333333;
    line-height: 0.8rem;
    width: 3.26rem;
    height: 0.8rem;
    text-align: center;
    background: #fad538;
    border-radius: 0.08rem;
  }
}

.tan {
  padding: 0.2rem 0;

  .quedin {
    padding: 0.3rem 1rem;
    background-color: #90c4ff;
    text-align: center;
    color: #ffffff;
    border-radius: 6px;
    margin-top: 0.3rem;
  }
}
.list_item0 {
		position: fixed;
		z-index: 999;
		font-size: .28rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.4rem 0;
		width: 90%;
		background-color: rgb(248, 248, 248);
		border-bottom: 1px solid #EDEDED;
		.left {
			.time {
				color: #666666;
				font-size: 0.32rem;
				font-weight: bold;
				align-items: center;
			}
		}
		.icon {
			margin-right: .1rem;
			img {
				width: .48rem;
				display: block;
			}
		}
		.right {
			color: #666666;
			font-size: 0.32rem;
			font-weight: bold;
			align-items: center;
		}
		
	}
.list_item0 {
		position: fixed;
		z-index: 999;
		font-size: .28rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.4rem 0;
		width: 90%;
		background-color: rgb(248, 248, 248);
		border-bottom: 1px solid #EDEDED;
		.left {
			.time {
				color: #666666;
				font-size: 0.32rem;
				font-weight: bold;
				align-items: center;
			}
		}
		.icon {
			margin-right: .1rem;
			img {
				width: .48rem;
				display: block;
			}
		}
		.right {
			color: #666666;
			font-size: 0.32rem;
			font-weight: bold;
			align-items: center;
		}
		
	}
.tan1 {
	padding: 0.2rem 0;
	padding-top: 1.26rem;
	padding-bottom: 1.2rem;
	.list_item {
		font-size: .28rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0.4rem .25rem;
		border-bottom: 1px solid #EDEDED;
		.item_left {
			display: flex;
			align-items: center;
      flex: 1;
			.left {
				color: #666666;
				display: flex;
				align-items: center;
        // word-break: normal;
			}
			.icon {
				margin-right: .1rem;
				img {
					width: .48rem;
					display: block;
				}
			}
			.right {
				color: #5569FC;
        width: calc(1rem + 30px);
			}
			.inp1 {
				font-weight: bold;
				height: .48rem;
				border: 0;
				width: 1rem;
				text-align: right;
				background-color: #F8F8F8;
			}
		}
		.item_right {
			display: flex;
			align-items: center;
			.left {
				color: #666666;
				display: flex;
				align-items: center;
			}
			.icon {
				margin-right: .1rem;
				img {
					width: .48rem;
					display: block;
				}
			}
			.right {
				color: #5569FC;
			}
			.inp1 {
				font-weight: bold;
				height: .48rem;
				border: 0;
				width: 0.7rem;
				text-align: right;
				background-color: #F8F8F8;
			}
		}
	}
	.quedin1 {
		position: fixed;
		bottom: 0.1rem;
		z-index: 999;
		padding: 0.3rem 0;
    left: 16px;
    right: 16px;
		background: linear-gradient(
		131deg, #527EFF 0%, #5569FC 100%);
		text-align: center;
		color: #FFFFFF;
		border-radius: 6px;
	}
}
.xiala {
  margin-left: 0.04rem;

  img {
    width: 0.22rem;
    height: 0.14rem;
  }
}

.icon_arrR {
  width: 0.2rem;
  height: 0.2rem;
}

.mid_list-cell {
  font-size: 0.28rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 0.25rem;

  .left {
    margin-left: 0.56rem;
    color: #666666;
    display: flex;
    align-items: center;
  }

  .icon {
    margin-right: 0.1rem;

    img {
      width: 0.48rem;
      display: block;
    }
  }

  .right {
    color: #efba0d;
    display: flex;
    align-items: center;

    img {
      margin-left: 0.14rem;
      width: 0.18rem;
      height: 0.1rem;
    }
  }

  .inp {
    height: 0.48rem;
    border: 0;
    width: 2rem;
    text-align: right;
  }

  .inp1 {
    height: 0.48rem;
    border: 0;
    width: 2rem;
    text-align: right;
    background-color: #f8f8f8;
  }
}

// ----------20200410[end]---------
</style>
