<template>
	<div class="whole">
		<!-- <title-two url="/assets" title="充币" /> -->
		<!-- <common-head :title="$t('xinzeng.chongbi')" :is-return="true" @onclickRight="onclickRight"> -->
			<!-- <img slot="right" src="@/assets/imgs/icon/icon_search.png" alt="" /> -->
			<!-- <span class="text" slot="text">{{ $t('system.walletRecord') }}</span>
		</common-head> -->
		<van-nav-bar
		    :title="$t('xinzeng.chongbi')"
		    left-arrow
		    @click-left="onClickLeft"
			:right-text="$t('system.walletRecord')"
			@click-right="onclickRight"
		    safe-area-inset-top
		    fixed
		    z-index="9999"
		/>
		<div class="g_safe_top">
			<div class="layoutBox">
				<div class="ulList">
					<div class="list-cell" @click="showPicker = true">
						{{ token }}
						<!-- <van-dropdown-menu><van-dropdown-item position="bottom" v-model="token" :options="token_set" /></van-dropdown-menu> -->
						<div class="right">
							<span class="mainFontLightColor">{{ $t("system.choosebi") }}</span>
							<i class="icon_arrR" style="margin: 0;"></i>
						</div>
					</div>
			
					<div class="chainInfo">
						<div class="hd">
							<span>{{ $t('system.Chainname') }}</span>
							<span>{{ this.mainchain }}</span>
						</div>
						<div class="bd">
							<div class="code"><div id="qrcode" ref="qrcode"></div></div>
							<p class="codeTit">{{ $t('system.chongbiAddress') }}</p>
							<div class="address mainFontColor">{{ address }}</div>
							<div :data-clipboard-text="address" @click="copyDomValue()" class="copy">{{ $t('system.copy') }}</div>
						</div>
					</div>
			
					<div class="chainTips">{{ $t('system.explain') }}</div>
				</div>
			</div>
			
			<!-- 币种选择 -->
			<van-action-sheet
				  v-model="showPicker"
				  :round="false"
				  :actions="token_set"
				  @select="onSelectPeriod"
				></van-action-sheet>
		</div>
	</div>
</template>

<script>
import commonHead from '@/components/commonHead';
import Clipboard from 'clipboard';
// 地址转换二维码插件
import QRCode from 'qrcodejs2';
export default {
	name: 'chargeMoney',
	components: { commonHead },
	data() {
		return {
			show: false,
			token: 'USDT',
			token_set: [],
			// address: "",
			deposit_min: 0,
			mainchain_set: [],
			user_token_set: [],
			lian_name: '',
			flag: true,
			id: '',
			address: '',
			// value1: 0,
			mainchain: 'TRX',
			coinType: 'TRX',
			currencys: '1',
			// 获取币种类型
			currencyType: [
				// 火必
				{ text: '', value: '1' },
				// 币安s
				{ text: '', value: '2' }
			],
			// 20210415----------
			showPicker: false,
			periodChoiceSet: [{ name: 'USDT' }],
		};
	},
	mounted() {
		this.getSupportedTokens();
	},
	methods: {
		onclickRight() {
				  this.$router.push({
					  path: '/walletRecord',
					  query: {
						  cashType: 'deposit'
					  }
				  })
		},
		onSelectPeriod(item) {
			console.log(item)
		    this.showPeriodChoice = false;
		    this.token = item.name;
		    this.showPicker = false;
			if(item.name == 'FOW') {
				this.mainchain = this.mainchain_set[1];
				this.getDepositAddress()
			}else if(item.name == 'USDT') {
				this.mainchain = this.mainchain_set[1];
				this.getDepositAddress()
			}
		},
		copyDomValue() {
			var clipboard = new Clipboard('.copy');
			clipboard.on('success', e => {
				// success("复制成功"); //这里你如果引入了elementui的提示就可以用，没有就注释即可
				this.$toast('复制成功！');
				console.log('复制成功！');
				console.log(e.text);
				// 释放内存
				clipboard.destroy();
			});
			clipboard.on('error', e => {
				// 不支持复制
				console.log('该浏览器不支持自动复制');
				// 释放内存
				clipboard.destroy();
			});
			console.log(this.copy);
		},
		choicetoken(e) {
			console.log(e.token);
			this.token = e.token;
			this.getDepositAddress();
			this.id = e.id;
			this.show = false;
		},
		choice(e) {
			this.token = e.token;
			this.getDepositAddress();
			this.id = e.id;
		},

		qrcode(address) {
			// console.log(address)
			new QRCode('qrcode', {
				width: 223.5, // 设置宽度，单位像素
				height: 223.5, // 设置高度，单位像素
				text: address // 设置二维码内容或跳转地址
			});
		},
		// 获取地址
		getDepositAddress() {
			this.$post2('Pay/Api/Index/getDepositAddress', {
				token: this.token,
				mainchain: this.mainchain
			})
				.then(res => {
					document.getElementById('qrcode').innerHTML = '';
					this.address = res.address;
					// this.deposit_min = res.deposit_min;
					this.$nextTick(() => {
						this.qrcode(res.address);
					});
				})
				.catch(err => {
					this.$dialog.alert({
						message: this.$t('xinzeng.buzhichi')
					});
				});
		},
		onClickLeft() {
			this.$router.back(-1);
		},

		//获取支持的币种
		getSupportedTokens() {
			this.$post2('Pay/Api/Index/getSupportedTokens')
				.then(res => {
					this.mainchain_set = res.mainchain_set;
					// this.token_set = res.token_set;
					this.mainchain = this.mainchain_set[1]||this.mainchain_set[0];
					console.log(this.mainchain);
					res.token_set.forEach(item=> {
						this.token_set.push({ name: item.value })
					})
					this.getDepositAddress();

					// this.currencyType[0].text = res.TRX[0];
					// this.currencyType[1].text = res.TRX[1];

					// console.log(this.currencyType[0].text);
					// console.log(this.currencyType[1].text);
				})
				.catch(err => {
					this.$toast('请检查网络');
				});
		}
	}
};
</script>

<style scoped lang="less">
.van-dropdown-item__option {
	margin-top: 5rem;
}
.van-cell--clickable {
	margin-top: 7rem;
}
.btnStyle {
	text-align: center;
	padding: 0.1rem;
	/* width: 1rem; */
	/* padding:1rem 1rem 1rem 1rem; */
	color: #ffffff;
	/* padding: 0px; */
	/* height: 0.5rem; */
	/*background: #ecbb34;*/
	border-radius: 5px;
}
.inputStyle {
	border: 0px;
	width: 70%;
}

.copyStyle {
	color: #ecbb34;
	margin: 0rem 0rem 0rem 2.7rem;
}

.imgPStyle {
	padding: 0.1rem 0.2rem 0.1rem 0.2rem;
	font-size: 0.4rem;
	border: 1px solid #14aa41;
	width: 3rem;
	color: #14aa41;
	border-radius: 5px;
}

.imgDiv {
	text-align: center;
	padding: 0.7rem 1.77rem 0rem;
}

.imgStyle {
	margin: 1rem 0 0.6rem 0;
	width: 2.8rem;
}
.bth_1 {
	margin-top: 1rem;
	color: #ff4b04;
	padding: 3px;
	border: 2px solid #ff4b04;
	border-radius: 5px;
}

.daiBi {
	margin-top: 0.7rem;
}
.ulList {
	margin-top: 0.2rem;
	padding: 0 0.2rem;
	.list-cell {
		height: 1.18rem;
		line-height: 1.18rem;
		padding: 0 0.25rem;
		background: #ffffff;
		border-radius: 8px;
		margin-bottom: 0.1rem;
		position: relative;
		font-size: 0.28rem;
		color: #333333;
		font-weight: bold;
		.right {
			position: absolute;
			z-index: 1;
			right: 0.25rem;
			bottom: 0;
		}
	}
}
.fdStyle {
	background: #f1f1f1;
	height: 100%;
	/* padding: 0.3rem; */
}
.van-icon-arrow-left {
	color: #ffffff;
}

.van-hairline--bottom {
	background: #ecbb34;
	color: #ffffff;
}

.van-nav-bar__title {
	background: #ecbb34;
	color: #ffffff;
}

.whole {
	font-family: PingFangSC-Regular;
	/* background: #ffffff; */
}
.title {
	background: #f6f8f9;
	height: 2rem;
	padding: 0.3rem;
}
.titleGuanLiStyle {
	font-size: 0.4rem;
	padding-top: 1.5rem;
}
.titlePStyle {
	margin-bottom: 0.2rem;
	margin-top: 0.3rem;
	padding-top: 0.1rem;
	transform: rotate(180deg);
}

.layoutBox {
	min-height: calc(100vh - 1.08rem);
}
.chainInfo {
	margin-top: 0.2rem;
	padding:0 0.25rem;
	background: #ffffff;
	border-radius: 0.16rem;
	padding-bottom: 0.6rem;
	.hd {
		font-family: pingfang SC;
		padding: 0.3rem 0;
		color: #333333;
		font-size: 0.28rem;
		line-height: 0.44rem;
		border-bottom: 0.01rem solid #D3D9DE;
		display: flex;
		justify-content: space-between;
		span {
			font-weight: bold;
		}
	}
	.bd {
		font-size: 0.24rem;
		line-height: 0.4rem;
		text-align: center;
	}
	.code {
		width: 4.46rem;
		height: 4.46rem;
		margin: 0 auto;
		margin-top: 0.3rem;
		display: flex;
		#qrcode{
			margin:auto;
		}
	}
	.codeTit {
		margin-top: 0.3rem;
		font-size: 0.24rem;
		color: #333333;
		// margin-bottom: 0.1rem;
	}
	.address {
		margin-top: 0.08rem;
		color: #333333;
		font-size: 0.24rem;
	}
	.copy {
		font-size: 0.24rem;
		margin-top: 0.2rem;
		color: #EFBA0D;
		display: inline-block;
		padding: 0.04rem 0.2rem;
		border: 0.01rem solid #EFBA0D;
		border-radius: 0.08rem;
	}
}
.chainTips {
	width: 7.5rem;
	margin-left: -0.25rem;
	margin-top: 0.2rem;
	color: #6F7A8B;
	font-size: 0.24rem;
	transform: scale(0.83);
}
.mainFontLightColor {
	margin-right: 0.1rem;
	color: #666666 !important;
	font-size: 0.24rem;
}
.icon_arrR {
	width: .2rem;
	height: .2rem;
	border-top: .04rem solid #666;
	border-right: .04rem solid #666;
}
</style>
